import { compose } from 'recompose';
import connect from 'react/hoc/connectProxy';

import { ACTIONS } from 'redux/versions/constants';
import { updateProductVersion } from 'redux/versions/actions';
import { selectPatchVersionIsLoading } from 'redux/versions/selectors';

export default compose(
  connect(
    state => ({
      isSubmitting: selectPatchVersionIsLoading(state),
    }),
    (dispatch, props) => ({
      onSubmit: async ({ ...version }) => {
        const versionToUpdate = {
          ...version,
          id: props.version.id,
        };

        const action = await dispatch(updateProductVersion(versionToUpdate));

        return action.type === ACTIONS.PATCH_PRODUCT_VERSION_SUCCESS ?
          props.onSubmitSuccess?.(action.version)
          : props.onSubmitFailure?.(action.error);
      },
    }),
  ),
);
