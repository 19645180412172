export const userIsProductDomainLeader
  = (user, product) => user.domainsWhereUserIsLeader?.some(id => id === product.domainId);

export const userIsProductCommunityArchitect
  = (user, product) => user.communitiesWhereUserIsArchitect?.some(id => id === product.communityId);

export const userIsBusinessUnitArchitect
  = (user, product) => user.businessUnitsWhereUserIsArchitect?.some(
    id => id === product.businessUnitInCharge,
  );
