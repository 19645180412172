import { defineMessages } from 'react-intl';

export default defineMessages({
  FIELD_NICKNAME_PLACEHOLDER: {
    id: 'deployment.form.nickname.placeholder',
    defaultMessage: 'Nickname',
  },
  FIELD_BU_REFERENCE_PLACEHOLDER: {
    id: 'deployment.form.buReference.placeholder',
    defaultMessage: 'Select the Business Unit Reference',
  },
  FIELD_MODULES_PLACEHOLDER: {
    id: 'deployment.form.modules.placeholder',
    defaultMessage: 'Select Modules',
  },
  CIA: {
    id: 'deployment.form.cia',
    defaultMessage: 'CIA',
  },
  FIELD_CIA_C: {
    id: 'deployment.form.cia.c',
    defaultMessage: 'C',
  },
  FIELD_CIA_I: {
    id: 'deployment.form.cia.i',
    defaultMessage: 'I',
  },
  FIELD_CIA_D: {
    id: 'deployment.form.cia.a',
    defaultMessage: 'A',
  },
});
