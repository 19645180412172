import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import cn from 'classnames';

import GenericRadio from 'react/generic/radio/Radio';
import reduxFormInputAdapter from '../reduxFormInputAdapter';
import Field from '../field/Field';

import classNames from './radio.module.scss';

const enhancer = compose(
  memo,
);

const RadioField = ({
  className,
  title,
  tooltip,
  error,
  ...props
}) => (
  <Field
    title={title}
    tooltip={tooltip}
    error={error}
  >
    <div className={classNames.optionsContainer}>
      <GenericRadio
        {...props}
        className={cn(
          className,
          error && classNames.hasError,
        )}
      />
    </div>
  </Field>
);

RadioField.propTypes = {
  className: PropTypes.string,
  title: PropTypes.node,
  tooltip: PropTypes.node,
  error: PropTypes.node,
};

RadioField.defaultProps = {
  className: null,
  title: null,
  tooltip: null,
  error: null,
};

export default enhancer(RadioField);

export const ReduxFormRadio = compose(
  enhancer,
  reduxFormInputAdapter,
)(RadioField);
