import { createSelector } from 'reselect';
import moment from 'moment';

export const selectProductVersions = (state, productId) => state.versions.map[productId];

export const selectProductVersionsList = createSelector(
  selectProductVersions,
  productVersionsMap => Object.values(productVersionsMap || {})
    .sort((a, b) => {
      if (!a.released) return 1;
      if (!b.released) return -1;
      return moment(a.released).isBefore(b.released) ? 1 : -1;
    }),
);

export const selectGetVersionsIsLoading = state => !!state.versions.isLoading.getVersions;
export const selectPostVersionIsLoading = state => !!state.versions.isLoading.postVersion;
export const selectPatchVersionIsLoading = state => !!state.versions.isLoading.patchVersion;
export const selectDeleteVersionIsLoading = state => !!state.versions.isLoading.deleteVersion;

export const selectVersionsIsLoading = createSelector(
  [
    selectGetVersionsIsLoading,
    selectPostVersionIsLoading,
    selectPatchVersionIsLoading,
    selectDeleteVersionIsLoading,
  ],
  (...args) => args.some(v => v),
);
