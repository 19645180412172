import React, { memo } from 'react';
import { compose } from 'recompose';
import { FormattedMessage } from 'react-intl';

import FieldsGroupHeader from 'react/generic/form/fieldsGroup/header/FieldsGroupHeader';
import FieldsGroup from 'react/generic/form/fieldsGroup/FieldsGroup';

import manifest from './architectureBuildingBlocks.manifest';
import messages from './architectureBuildingBlocks.messages';

const {
  fields: {
    architectureBuildingBlocks: { component: ArchitectureBuildingBlocksField },
  },
} = manifest;

const enhancer = compose(
  memo,
);

const ProductAbbForm = () => (
  <>
    <FieldsGroup>
      <FieldsGroupHeader
        title={<FormattedMessage {...messages.ABB_TITLE} />}
        message={<FormattedMessage {...messages.ABB_HINT} />}
      />
      <ArchitectureBuildingBlocksField />
    </FieldsGroup>
  </>
);

export default enhancer(ProductAbbForm);
