import { compose, withProps, withHandlers } from 'recompose';
import { createForm } from 'rc-form';
import moment from 'moment';

import { htmlToEditorState, editorStateToHtml } from 'react/generic/form/wysiwyg/wysiwyg.utils';

export default compose(
  createForm(),

  withHandlers({
    onSubmit: ({ form, onSubmit, productId }) => (event) => {
      event.preventDefault();
      event.stopPropagation();

      form.validateFields((err, values) => {
        if (!err) {
          const released = values.released?.format('YYYY-MM-DD');
          onSubmit({
            ...values,
            presentation: editorStateToHtml(values.presentation),
            product: productId,
            released,
          });
        }
      });
    },
  }),

  withProps(({
    form,
    isSubmitting,
    version,
  }) => ({
    canSubmit: !isSubmitting
    && !!form.getFieldValue('title')
    && !!form.getFieldValue('status')
    && form.getFieldValue('presentation')?.getCurrentContent()?.hasText(),

    titleDecorator: form.getFieldDecorator(
      'title',
      {
        initialValue: version?.title,
      },
    ),

    statusDecorator: form.getFieldDecorator(
      'status',
      {
        initialValue: version?.status,
      },
    ),

    releasedDecorator: form.getFieldDecorator(
      'released',
      {
        initialValue: version?.released && moment(version.released, 'YYYY-MM-DD'),
      },
    ),

    descriptionDecorator: form.getFieldDecorator(
      'presentation',
      {
        initialValue: htmlToEditorState(version?.presentation),
      },
    ),
  })),
);
