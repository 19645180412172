import { compose, withPropsOnChange, lifecycle } from 'recompose';
import { difference } from 'lodash';

import connect from 'react/hoc/connectProxy';
import omitProps from 'react/hoc/omitProps';

import { selectProductById } from 'redux/products/selectors';
import { selectBusinessUnitsFromDeployments } from 'redux/deployments/selectors';
import { selectBusinessUnitsByIds } from 'redux/businessUnits/selectors';
import { getBusinessUnits } from 'redux/businessUnits/actions';

import Autocomplete from 'react/generic/form/autocomplete/Autocomplete';
import findLabelInOptions from 'react/generic/form/autocomplete/findLabelInOptions';

import { mapBu } from './businessUnits.constants';

export default compose(
  connect(
    (state, props) => ({
      businessUnitsUsingProduct: selectProductById(
        state,
        props.productId,
      ).businessUnitsUsingProduct,
      businessUnitsDeployments: selectBusinessUnitsFromDeployments(
        state,
        props.productId,
        props.versionId,
      ),
    }),
    { getBusinessUnits },
  ),

  withPropsOnChange(
    ['businessUnitsUsingProduct', 'businessUnitsDeployments'],
    props => ({
      businessUnitsAvailableList: difference(
        props.businessUnitsUsingProduct,
        props.businessUnitsDeployments,
      ),
    }),
  ),

  lifecycle({
    /** Did mount. */
    async componentDidMount() {
      await this.props.getBusinessUnits();
    },
  }),

  connect(
    (state, props) => ({
      businessUnits: selectBusinessUnitsByIds(state, props.businessUnitsAvailableList),
    }),
  ),

  withPropsOnChange(
    ['businessUnits'],
    ({ businessUnits }) => ({
      options: Object.values(businessUnits).map(mapBu),
    }),
  ),

  omitProps([
    'businessUnitsUsingProduct',
    'businessUnitsDeployments',
    'businessUnitsAvailableList',
    'businessUnits',
  ]),

  findLabelInOptions,
)(Autocomplete);
