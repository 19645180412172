import { PRODUCT_FORM_STEPS } from './form-steps.constants';
import presentationManifest from './step-1/presentation.manifest';
import featuresManifest from './step-2/features.manifest';
import teamManifest from './step-3/team.manifest';
import businessUnitsManifest from './step-4/businessunits.manifest';
import linksManifest from './step-5/links.manifest';
import businessActivitiesManifest from './step-6/business-activities-form.manifest';
import architectureBuildingBlocksManifest from './step-7/architectureBuildingBlocks.manifest';

export const PRODUCT_FORM_STEPS_MANIFESTS = {
  [PRODUCT_FORM_STEPS.PRESENTATION]: presentationManifest,
  [PRODUCT_FORM_STEPS.FEATURES]: featuresManifest,
  [PRODUCT_FORM_STEPS.TEAM]: teamManifest,
  [PRODUCT_FORM_STEPS.BUSINESS_UNITS]: businessUnitsManifest,
  [PRODUCT_FORM_STEPS.LINKS]: linksManifest,
  [PRODUCT_FORM_STEPS.BUSINESS_ACTIVITIES]: businessActivitiesManifest,
  [PRODUCT_FORM_STEPS.ARCHITECTURE_BUILDING_BLOCKS]: architectureBuildingBlocksManifest,
};
