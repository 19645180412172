/* eslint-disable react/forbid-prop-types */
import React, { memo } from 'react';
import { compose, lifecycle } from 'recompose';

import { required } from 'react/generic/form/validators';
import { PRODUCT_STAGES } from 'config/constants';
import { stagesInfoLink, stagesOptions } from 'react/business/products/stages/stages.constants';

import { Field, change as changeAction } from 'redux-form';
import { FormattedMessage } from 'react-intl';
import { ReduxFormSelectWithValueSelector } from 'react/generic/form/select/Select';

import connect from 'react/hoc/connectProxy';
import { selectProductFormValuesAsStoreProduct } from 'redux/products/selectors';
import { isInitiativeType, isInCreateModeType } from '../propTypes';

import messages from './stage.messages';

const rules = [required];

const stagesOptionsProduct = stagesOptions.filter(stage => stage.value !== PRODUCT_STAGES.INI);

const enhancer = compose(
  memo,

  connect(
    (state, props) => ({
      form: selectProductFormValuesAsStoreProduct(state, props.formName),
    }),
    { change: changeAction },
  ),

  lifecycle({
    /**
     * Update status of modules.
     *
     * @param {object} prevProps - Previous props.
     */
    componentDidUpdate(prevProps) {
      const newProductStage = this.props.form.stage;
      const previousStage = prevProps.form.stage;
      const eolStages = [PRODUCT_STAGES.DEP, PRODUCT_STAGES.DEC, PRODUCT_STAGES.ARCH];
      let updatedModules = {};
      if (previousStage !== newProductStage && eolStages.includes(newProductStage)) {
        updatedModules = this.props.form.modules.map((module) => {
          if (newProductStage === PRODUCT_STAGES.DEC
              && !eolStages.includes(module.status)) {
            return {
              ...module,
              status: PRODUCT_STAGES.DEC,
            };
          }
          if (newProductStage === PRODUCT_STAGES.DEP
              && ![PRODUCT_STAGES.DEP, PRODUCT_STAGES.ARCH].includes(module.status)) {
            return {
              ...module,
              status: PRODUCT_STAGES.DEP,
            };
          }
          if (newProductStage === PRODUCT_STAGES.ARCH) {
            return {
              ...module,
              status: PRODUCT_STAGES.ARCH,
            };
          }
          return module;
        });
        this.props.change(this.props.formName, 'modules', updatedModules);
      }
    },
  }),
);

const TooltipBody = () => (
  <>
    <FormattedMessage {...messages.STAGES_HINT} />
    <a href={stagesInfoLink} target="_blank" rel="noopener noreferrer"> <FormattedMessage {...messages.STAGES_LINK} /> </a>
  </>
);

const StageField = ({
  isInitiative, isInCreateMode,
}) => (
  <Field
    type="text"
    name="stage"
    title={<FormattedMessage {...messages.STAGES_TITLE} />}
    tooltip={<TooltipBody />}
    component={ReduxFormSelectWithValueSelector}
    options={isInitiative ? stagesOptions : stagesOptionsProduct}
    isDisabled={isInitiative && isInCreateMode}
    isSearchable={false}
    validate={rules}
    specific
  />
);

StageField.displayName = 'StageField';

StageField.propTypes = {
  isInitiative: isInitiativeType,
  isInCreateMode: isInCreateModeType,
};

StageField.defaultProps = {
  isInitiative: false,
  isInCreateMode: false,
};

export default enhancer(StageField);
