import agent from 'services/http';
import { mapDeploymentFromServer } from './deployments.mapper';

const e = encodeURIComponent;

/**
 * GET /api/products/:productId/deployments.
 * Get the list of deployments of a product.
 *
 * @async
 * @param {string} productId - Product id.
 * @returns {{deployments: Array<object>, count: number}} Results.
 */
export async function getProductDeployments(productId) {
  const { body } = await agent
    .get(`/api/products/${e(productId)}/deployments`);

  return (body || []).map(mapDeploymentFromServer);
}

/**
 * POST /api/products/:productId/deployments.
 * Create a new deployment on a product.
 *
 * @async
 * @param {string} productId - Product id.
 * @param {object} deployment - Deployment to create.
 * @returns {object} Created deployment.
 */
export async function postProductDeployment(productId, deployment) {
  const { body } = await agent
    .post(`/api/products/${e(productId)}/deployments`)
    .send(deployment);

  return mapDeploymentFromServer(body);
}

/**
 * PATCH /api/products/:productId/deployments/:deploymentId.
 * Update a existing deployment.
 *
 * @async
 * @param {string} productId - Product id.
 * @param {object} deployment - Deployment to update.
 * @returns {object} Updated deployment.
 */
export async function patchProductDeployment(productId, deployment) {
  const { body } = await agent
    .patch(`/api/products/${e(productId)}/deployments/${e(deployment.id)}`)
    .send(deployment);

  return mapDeploymentFromServer(body);
}

/**
 * DELETE /api/products/:productId/deployments/:deploymentId.
 * Delete a deployment on a product.
 *
 * @async
 * @param {string} productId - Product id.
 * @param {string} deploymentId - Deployment id.
 * @returns {object} Http response body.
 */
export async function deleteProductDeployment(productId, deploymentId) {
  const { body } = await agent
    .delete(`/api/products/${e(productId)}/deployments/${e(deploymentId)}`);

  return body;
}
