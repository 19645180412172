import { defineMessages } from 'react-intl';

export default defineMessages({
  FIELD_TITLE_PLACEHOLDER: {
    id: 'version.form.title.placeholder',
    defaultMessage: 'Title',
  },
  FIELD_STATUS_PLACEHOLDER: {
    id: 'version.form.status.placeholder',
    defaultMessage: 'Status',
  },
  FIELD_DATE_PLACEHOLDER: {
    id: 'version.form.date.placeholder',
    defaultMessage: 'Date',
  },
  FIELD_PRESENTATION_PLACEHOLDER: {
    id: 'version.form.presentation.placeholder',
    defaultMessage: 'Description',
  },
});
