import { noop, omit } from 'lodash';
import { compose, defaultProps } from 'recompose';
import connect from 'react/hoc/connectProxy';

import { ACTIONS as ACTIONS_PRODUCT } from 'redux/products/constants';
import { ACTIONS as ACTIONS_SECURITY } from 'redux/security/constants';
import { editProduct, getProduct } from 'redux/products/actions';
import { selectIsEditProductLoading } from 'redux/products/selectors';
import { isLoadingPatchSecurityData } from 'redux/security/selectors';
import { getSecurityData, patchSecurityData } from 'redux/security/actions';
import { editorStateToHtml } from '../../../../../generic/form/wysiwyg/wysiwyg.utils';

export default compose(
  defaultProps({
    onSubmitSuccess: noop,
    onSubmitFailure: noop,
  }),

  connect(
    (state, props) => ({
      isLoading: selectIsEditProductLoading(state, props.product.id)
        || isLoadingPatchSecurityData(state),
    }),
    (dispatch, props) => ({
      onSubmit: async (data) => {
        const formattedData = omit({
          ...data,
          cid: {
            c: data.cidc,
            i: data.cidi,
            d: data.cidd,
          },
          scheduledMaintenanceRange: editorStateToHtml(data.scheduledMaintenanceRange || ''),
          requestedServices: editorStateToHtml(data.requestedServices || ''),
          supportTimeRange: {
            value: data.supportTimeRangeStart?.format().split('+')[0],
            end_value: data.supportTimeRangeEnd?.format().split('+')[0],
          },
          linkToServiceCatalog: {
            uri: data.linkToServiceCatalogUrl,
            title: data.linkToServiceCatalogTitle,
          },
          availabilityInfoLink: {
            uri: data.availabilityInfoLinkUrl,
            title: data.availabilityInfoLinkTitle,
          },
          uptimeInfoLink: {
            uri: data.uptimeInfoLinkUrl,
            title: data.uptimeInfoLinkTitle,
          },
          latencyInfoLink: {
            uri: data.latencyInfoLinkUrl,
            title: data.latencyInfoLinkTitle,
          },
        }, [
          'cidc',
          'cidi',
          'cidd',
          'supportTimeRangeStart',
          'supportTimeRangeEnd',
          'linkToServiceCatalogUrl',
          'linkToServiceCatalogTitle',
          'availabilityInfoLinkUrl',
          'availabilityInfoLinkTitle',
          'uptimeInfoLinkUrl',
          'uptimeInfoLinkTitle',
          'latencyInfoLinkUrl',
          'latencyInfoLinkTitle',
        ]);

        const formattedSecurityData = omit(
          {
            id: props.security?.id,
            lastBIADate: data?.lastBIADate ? new Date(data?.lastBIADate).toISOString() : null,
            digitalComponentId: props.product.id,
          },
          [],
        );

        const actionProduct = await dispatch(
          editProduct(
            props.product.id,
            props.product.id,
            {
              ...formattedData,
              personalData: data.personalData === 'N/A' ? null : data.personalData === 'TRUE',
            },
          ),
        );

        const actionSecurity = await dispatch(
          patchSecurityData(props.product.id, formattedSecurityData),
        );

        if (actionProduct.type === ACTIONS_PRODUCT.EDIT_PRODUCT_SUCCESS
           && actionSecurity.type === ACTIONS_SECURITY.PATCH_SECURITY_DATA_SUCCESS) {
          await dispatch(getProduct(props.product.id));
          await dispatch(getSecurityData(props.product.id));
          return props.onSubmitSuccess();
        }
        return props.onSubmitFailure(actionProduct.error);
      },
    }),
  ),
);
