import React, { memo } from 'react';
import { compose, withStateHandlers } from 'recompose';
import { productShape } from 'shapes/product';
import messages from 'react/pages/product/productPage/productpage.messages';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import AdditionalInfoForm from 'react/business/products/additionalInfo/form/standalone/ProductAdditionalInfoFormStandalone';
import ProductSubHeader from 'react/business/products/details/subHeader/ProductSubHeader';
import EditProductButton from 'react/business/products/editButton/editProductButton/EditProductButton';
import AdditionalInfoList from 'react/business/products/additionalInfo/list/AdditionalInfoList';
import { securityShape } from 'shapes/security';
import { withProductPageContext } from '../../ProductPage.context';

const enhancer = compose(
  withProductPageContext(({ product, security, canEditProduct }) => (
    { product, security, canEditProduct }
  )),

  withStateHandlers(
    () => ({
      isInEditMode: false,
    }),
    {
      onEdit: () => () => ({ isInEditMode: true }),
      onSuccess: () => () => ({ isInEditMode: false }),
      onCancelEdit: () => () => ({ isInEditMode: false }),
    },
  ),

  memo,
);

const AdditionalInfoPage = ({
  product,
  security,
  canEditProduct,
  isInEditMode,
  onEdit,
  onSuccess,
  onCancelEdit,
}) => (
  <>
    {
      (canEditProduct && !isInEditMode) && (
        <ProductSubHeader>
          <EditProductButton
            productId={product.id}
            onClickEdit={onEdit}
            labelButton={<FormattedMessage {...messages.EDIT_ADDITIONAL_BUTTON} />}
          />
        </ProductSubHeader>
      )
    }
    {
      isInEditMode ? (
        <AdditionalInfoForm
          product={product}
          security={security}
          onSubmitSuccess={onSuccess}
          onCancelEdit={onCancelEdit}
        />
      ) : <AdditionalInfoList product={product} security={security} />
    }
  </>
);

AdditionalInfoPage.displayName = 'AdditionalInfoPage';

AdditionalInfoPage.propTypes = {
  product: productShape.isRequired,
  canEditProduct: PropTypes.bool,
  isInEditMode: PropTypes.bool.isRequired,
  onEdit: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  onCancelEdit: PropTypes.func.isRequired,
  security: securityShape,
};

AdditionalInfoPage.defaultProps = {
  security: null,
  canEditProduct: false,
};

export default enhancer(AdditionalInfoPage);
