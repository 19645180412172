import { defineMessages } from 'react-intl';

export default defineMessages({
  IS_MAINTAINED_BY_PLATFORM_TITLE: { id: 'product.form.fields.isMaintainedByPlatform.title', defaultMessage: 'Is the digital product maintained by the platform ? *' },

  IS_MAINTAINED_BY_PLATFORM_HINT: {
    id: 'product.form.fields.isMaintainedByPlatform.hint',
    defaultMessage: 'Define if the platform maintain this digital product by itself or not. If you don\'t know, fill in "No"',
  },
});
