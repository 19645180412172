import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import cn from 'classnames';

import globalMessages from 'config/global.messages';

import { FormattedMessage } from 'react-intl';
import Section from 'react/business/products/details/layout/section/Section';
import SectionTitle from 'react/business/products/details/layout/sectionTitle/SectionTitle';
import SafeHtml from 'react/generic/html/SafeHtml';

import withTruncateText from 'react/generic/message/messageCard/content/withTruncateText';

import classNames from './productDescription.module.scss';
import messages from './product-description.messages';

const enhancer = compose(
  memo,
  withTruncateText,
);

const ProductDescription = ({
  description,
  isExpanded,
  isTruncated,
  onCollapse,
  onExpand,
  detectContentTruncatedChange,
}) => (
  <Section className={classNames.description}>
    <SectionTitle
      centered
      className={classNames.sectionTitle}
    >
      <FormattedMessage {...messages.DESCRIPTION} />
    </SectionTitle>
    <SafeHtml
      forwardRef={detectContentTruncatedChange(description)}
      unsafeHtml={description}
      className={cn(
        classNames.descriptionHtml,
        !isExpanded && classNames.collapsed,
      )}
    />
    {
      isTruncated && (
        <div>
          <button
            type="button"
            className={classNames.seeMoreButton}
            onClick={isExpanded ? onCollapse : onExpand}
          >
            <FormattedMessage
              {...(isExpanded ? globalMessages.SEE_LESS : globalMessages.SEE_MORE)}
            />
          </button>
        </div>
      )
    }
  </Section>
);

ProductDescription.displayName = 'ProductDescription';

ProductDescription.propTypes = {
  description: PropTypes.string,
  isExpanded: PropTypes.bool,
  isTruncated: PropTypes.bool,
  onCollapse: PropTypes.func.isRequired,
  onExpand: PropTypes.func.isRequired,
  detectContentTruncatedChange: PropTypes.func.isRequired,
};

ProductDescription.defaultProps = {
  description: '',
  isExpanded: false,
  isTruncated: false,
};

export default enhancer(ProductDescription);
