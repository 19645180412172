import { Manifest } from 'services/products/products-form';

import {
  stage,
  community,
  domain,
  isMaintainedByPlatform,
  name,
  alias,
  isGlobal,
  logo,
  shortDescription,
  presentation,
  hostingType,
  softwarePublisher,
} from 'react/business/products/form/fields';

export default new Manifest({
  fields: {
    stage,
    community,
    domain,
    isMaintainedByPlatform,
    name,
    alias,
    isGlobal,
    logo,
    shortDescription,
    presentation,
    hostingType,
    softwarePublisher,
  },
});
