import { compose, withProps, withPropsOnChange } from 'recompose';

import reduxFormInputAdapter from 'react/generic/form/reduxFormInputAdapter';
import { withReduxFormDefaultValue } from 'react/generic/form/select/Select';
import Autocomplete from 'react/generic/form/autocomplete/Autocomplete';

import withBusinessUnitsOptions from './withBusinessUnitsOptions';

export default compose(
  withBusinessUnitsOptions,

  withProps(() => ({
    isMulti: false,
  })),

  reduxFormInputAdapter,
  withReduxFormDefaultValue,

  // If we only have the key (when the form is filled on edition),
  // we grab the label from the map.
  withPropsOnChange(
    ['businessUnitsMap', 'value'],
    ({ value, businessUnitsMap }) => ({
      // isInactive filtering could be done in withBusinessUnitsOptions as well
      value: value && !businessUnitsMap[value.value]?.isInactive ? {
        ...value,
        label: businessUnitsMap[value.value]?.name,
      } : null,
    }),
  ),
)(Autocomplete);
