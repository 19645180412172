import { createSelector } from 'reselect';
import { uniq } from 'lodash';

export const selectDeploymentsByProductId
  = (state, productId) => state.deployments.map[productId];

export const selectDeploymentsByVersionId
  = (state, productId, versionId) => ((state.deployments.map[productId] || {})[versionId]) || {};

export const selectDeploymentListByVersionId = createSelector(
  selectDeploymentsByVersionId,
  deploymentsMap => Object.values(deploymentsMap),
);

export const selectBusinessUnitsFromDeployments = createSelector(
  selectDeploymentListByVersionId,
  deployments => uniq(deployments.map(deployment => deployment.buReference)),
);

export const selectGetDeploymentsIsLoading
  = state => !!state.deployments.isLoading.getDeployments;
export const selectPostDeploymentIsLoading
  = state => !!state.deployments.isLoading.postDeployment;
export const selectPatchDeploymentIsLoading
  = state => !!state.deployments.isLoading.patchDeployment;
export const selectDeleteDeploymentIsLoading
  = state => !!state.deployments.isLoading.deleteDeployment;
