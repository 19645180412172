import { noop } from 'lodash';
import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { compose, withHandlers, lifecycle } from 'recompose';
import cn from 'classnames';

import omitProps from 'react/hoc/omitProps';

import { stepValueType } from './steps.shapes';

import classNames from './steps.module.scss';
import BadgeCount from '../badge/BadgeCount';

const enhancer = compose(
  // Bind onClick to the value.
  withHandlers({
    onClick: ({ value, onClick }) => () => onClick?.(value),
    onTouch: ({ value, onTouch }) => () => onTouch?.(value),
  }),

  lifecycle({
    /**
     * Set as touched if this was the current step.
     *
     * @param {object} prevProps - Previous props.
     */
    componentDidUpdate(prevProps) {
      if (prevProps.isCurrent && !this.props.isCurrent) {
        this.props.onTouch();
      }
    },
  }),

  // The bottom component does not need the value.
  omitProps(['value', 'onTouch']),

  memo,
);

const Step = ({
  icon,
  iconActive,
  title,
  subtitle,
  isCurrent,
  isTouched,
  isDisabled,
  errorsCount,
  warningsCount,
  className,
  buttonClassName,
  iconClassName,
  titleClassName,
  subtitleClassName,
  ...props
}) => (
  <li
    className={cn(
      className,
      classNames.step,
      (isTouched || isCurrent) && classNames.active,
      isDisabled && classNames.disabled,
    )}
  >
    <button
      {...props}
      type="button"
      disabled={isDisabled}
      className={cn(
        buttonClassName,
        classNames.button,
      )}
    >
      <div className={classNames.buttonContent}>
        { (icon || iconActive)
          && (
            <div className={cn(iconClassName, classNames.icon)}>
              { isTouched ?
                iconActive || icon
                : icon }
            </div>
          ) }

        { (title || subtitle)
          && (
            <div className={classNames.labelContainer}>
              { title
                && (
                  <div className={cn(titleClassName, classNames.title)}>
                    {title}
                  </div>
                ) }

              { subtitle
                && (
                  <div className={cn(subtitleClassName, classNames.subtitle)}>
                    {subtitle}
                  </div>
                ) }
            </div>
          ) }
      </div>

      { isTouched
        && !!(errorsCount || warningsCount)
        && (
          <BadgeCount
            text={errorsCount + warningsCount}
            className={cn(
              classNames.badge,
              errorsCount && classNames.hasErrors,
              !errorsCount && warningsCount && classNames.hasWarnings,
            )}
          />
        ) }
    </button>
  </li>
);

Step.displayName = 'Step';

Step.propTypes = {
  icon: PropTypes.node,
  iconActive: PropTypes.node,
  title: PropTypes.node,
  subtitle: PropTypes.node,
  isCurrent: PropTypes.bool,
  isTouched: PropTypes.bool,
  isDisabled: PropTypes.bool,
  errorsCount: PropTypes.number,
  warningsCount: PropTypes.number,
  className: PropTypes.string,
  buttonClassName: PropTypes.string,
  iconClassName: PropTypes.string,
  titleClassName: PropTypes.string,
  subtitleClassName: PropTypes.string,
};

Step.defaultProps = {
  icon: null,
  iconActive: null,
  title: null,
  subtitle: null,
  isCurrent: false,
  isTouched: false,
  isDisabled: false,
  errorsCount: 0,
  warningsCount: 0,
  className: null,
  buttonClassName: null,
  iconClassName: null,
  titleClassName: null,
  subtitleClassName: null,
};

const EnhancedStep = enhancer(Step);

EnhancedStep.displayName = 'Step';

EnhancedStep.propTypes = {
  value: stepValueType,
  onClick: PropTypes.func,
};

EnhancedStep.defaultProps = {
  value: null,
  onClick: noop,
};

export default EnhancedStep;
