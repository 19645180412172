import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { compose, withHandlers } from 'recompose';
import { noop } from 'lodash';

import { PRODUCT_FORM_STEPS } from 'react/business/products/form/steps/form-steps.constants';

import ProductFormArchitectureBuildingBlocksForm from 'react/business/products/form/steps/step-7/ArchitectureBuildingBlocksForm';
import ProductFormStepLayout from '../layout/StepLayout';

const enhancer = compose(

  withHandlers({
    onClickPreviousStep: ({ onChangeStep }) => () => onChangeStep(
      PRODUCT_FORM_STEPS.BUSINESS_ACTIVITIES,
    ),

    onClickNextStep: ({ onChangeStep }) => () => onChangeStep(PRODUCT_FORM_STEPS.SUBSETS),
  }),

  memo,
);

const ProductFormArchitectureBuildingBlocks = ({
  onClickPreviousStep,
  onOpenPreview,
  onClickNextStep,
}) => (
  <ProductFormStepLayout
    stepKey={PRODUCT_FORM_STEPS.ARCHITECTURE_BUILDING_BLOCKS}
    form={<ProductFormArchitectureBuildingBlocksForm />}
    onClickPreviousStep={onClickPreviousStep}
    onClickNextStep={onClickNextStep}
    onOpenPreview={onOpenPreview}
  />
);

ProductFormArchitectureBuildingBlocks.displayName = 'ProductFormArchitectureBuildingBlocks';

ProductFormArchitectureBuildingBlocks.propTypes = {
  onClickPreviousStep: PropTypes.func,
  onClickNextStep: PropTypes.func,
  onOpenPreview: PropTypes.func,
};

ProductFormArchitectureBuildingBlocks.defaultProps = {
  onClickPreviousStep: noop,
  onClickNextStep: noop,
  onOpenPreview: noop,
};

export default enhancer(ProductFormArchitectureBuildingBlocks);
