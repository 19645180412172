import withSubmitCreateVersion from 'react/business/versions/form/withSubmitCreateVersion';
import makeVersionFormDialog from '../VersionFormDialog';
import CreateSuccessStep from './steps/Success';
import CreateFailureStep from './steps/Failure';
import CreateFormStep from './steps/Form';

// Handle onSubmit to create a suggestion.
const SubmitCreateFormStep = withSubmitCreateVersion(CreateFormStep);

export default makeVersionFormDialog(
  SubmitCreateFormStep,
  CreateSuccessStep,
  CreateFailureStep,
);
