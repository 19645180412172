import { defineMessages } from 'react-intl';

export default defineMessages({
  FEATURES_HINT: {
    id: 'pages.product.form.steps.2.features.hint',
    defaultMessage: 'List the main business features of your digital product and in particular those that bring the most value to users. For example "Allow to give a feedback to a product". Do not forget to write them in english.',
  },

  SCREENSHOTS_HINT: {
    id: 'pages.product.form.steps.2.screenshots.hint',
    defaultMessage: 'The screenshots of your digital product must be in jpg or png format and less than 10 MB.',
  },

  HEADER_TITLE_FEATURES: {
    id: 'pages.product.form.step.2.features.header.title',
    defaultMessage: 'Feature List',
  },

  HEADER_TITLE_SCREENSHOTS: {
    id: 'pages.product.form.step.2.screenshots.header.title',
    defaultMessage: 'Product images',
  },

  HEADER_TITLE_TAGS: {
    id: 'pages.product.form.step.2.tags.header.title',
    defaultMessage: 'Innersource & Architecture',
  },

  TAGS_HINT: {
    id: 'pages.product.form.step.2.tags.header.message',
    defaultMessage: 'Keywords to target your product technologies, devices and hashtags.',
  },

  HEADER_TITLE_MODULES: {
    id: 'pages.product.form.step.2.modules.header.title',
    defaultMessage: 'Module list',
  },

  MODULES_HINT: {
    id: 'pages.product.form.step.2.modules.header.message',
    defaultMessage: 'List the modules of your digital product.',
  },
});
