import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { FormattedMessage } from 'react-intl';

import BusinessUnits from 'react/business/products/form/autocompletes/businessUnits/BusinessUnitsUsingProductForDeployments';
import Modules from 'react/business/products/form/autocompletes/Modules';
import Versions from 'react/business/products/form/autocompletes/Versions';
import { SelectWithValueSelector as Select } from 'react/generic/select/Select';
import FormattedMessageChildren from 'react/generic/i18n/FormattedMessageChildren';
import Input from 'react/generic/form/input/Input';

import {
  MAX_DEPLOYMENT_NICKNAME_LENGTH,
  PRODUCT_FIELDS_OPTIONS_CIA,
} from 'config/constants';

import messages from './fields.messages';
import classNames from './fields.module.scss';

const enhancer = compose(
  memo,
);

const DeploymentFormFields = ({
  nicknameDecorator,
  buReferenceDecorator,
  versionDecorator,
  modulesDecorator,
  cidcDecorator,
  ciddDecorator,
  cidiDecorator,
  productId,
  version,
}) => (
  <>
    <div className={classNames.nickNameContainer}>
      <FormattedMessageChildren {...messages.FIELD_NICKNAME_PLACEHOLDER}>
        { placeholder => nicknameDecorator(
          <Input placeholder={placeholder} maxLength={MAX_DEPLOYMENT_NICKNAME_LENGTH} />,
        ) }
      </FormattedMessageChildren>
    </div>
    <div className={classNames.buReferenceContainer}>
      <FormattedMessageChildren {...messages.FIELD_BU_REFERENCE_PLACEHOLDER}>
        { placeholder => buReferenceDecorator(
          <BusinessUnits
            placeholder={placeholder}
            productId={productId}
            versionId={version.value}
            multi={false}
          />,
        ) }
      </FormattedMessageChildren>
    </div>
    <div>
      {versionDecorator(<Versions productId={productId} />) }
    </div>
    <div>
      <FormattedMessageChildren {...messages.FIELD_MODULES_PLACEHOLDER}>
        { placeholder => modulesDecorator(
          <Modules placeholder={placeholder} productId={productId} multi />,
        ) }
      </FormattedMessageChildren>
    </div>
    <h4><FormattedMessage {...messages.FIELD_CIA} /></h4>
    <div className={classNames.cidContainer}>
      <div className={classNames.cidElement}>
        <FormattedMessageChildren {...messages.FIELD_CIA_C}>
          { placeholder => cidcDecorator(
            <Select
              options={PRODUCT_FIELDS_OPTIONS_CIA}
              className={classNames.cidElement}
              placeholder={placeholder}
            />,
          )
          }
        </FormattedMessageChildren>
      </div>
      <div className={classNames.cidElement}>
        <FormattedMessageChildren {...messages.FIELD_CIA_I}>
          { placeholder => cidiDecorator(
            <Select
              options={PRODUCT_FIELDS_OPTIONS_CIA}
              className={classNames.cidElement}
              placeholder={placeholder}
            />,
          )
        }
        </FormattedMessageChildren>
      </div>
      <div className={classNames.cidElement}>
        <FormattedMessageChildren {...messages.FIELD_CIA_D}>
          { placeholder => ciddDecorator(
            <Select
              options={PRODUCT_FIELDS_OPTIONS_CIA}
              className={classNames.cidElement}
              placeholder={placeholder}
            />,
          )
        }
        </FormattedMessageChildren>
      </div>
    </div>
  </>
);

DeploymentFormFields.displayName = 'DeploymentFormFields';

DeploymentFormFields.propTypes = {
  nicknameDecorator: PropTypes.func.isRequired,
  buReferenceDecorator: PropTypes.func.isRequired,
  versionDecorator: PropTypes.func.isRequired,
  modulesDecorator: PropTypes.func.isRequired,
  cidcDecorator: PropTypes.func.isRequired,
  ciddDecorator: PropTypes.func.isRequired,
  cidiDecorator: PropTypes.func.isRequired,
  productId: PropTypes.string.isRequired,
  version: PropTypes.objectOf(
    PropTypes.string,
    PropTypes.string,
  ).isRequired,
};

export default enhancer(DeploymentFormFields);
