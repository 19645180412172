import { compose, withProps, withPropsOnChange } from 'recompose';
import connect from 'react/hoc/connectProxy';

import { getUsersByExternalIds, searchUsersByFullName } from 'services/users';
import { selectUserByExternalId } from 'redux/users/selectors';

import reduxFormInputAdapter from 'react/generic/form/reduxFormInputAdapter';
import { withReduxFormDefaultValue } from 'react/generic/form/select/Select';
import Autocomplete from 'react/generic/form/autocomplete/Autocomplete';

const mapUser = user => ({
  value: user,
  label: `${user.firstName} ${user.lastName} (${user.externalId})`,
});

const isLDAP = val => /^\d+$/.test(val) && val.length === 8;

const searchUsers = async (query) => {
  if (!query) {
    return [];
  }

  let results = [];
  if (isLDAP(query)) {
    results = await getUsersByExternalIds(query);
  } else {
    results = await searchUsersByFullName(query);
  }

  return results.map(mapUser);
};

export default compose(
  withProps(
    () => ({
      loadOptions: searchUsers,
    }),
  ),

  reduxFormInputAdapter,
  withReduxFormDefaultValue,

  connect((state, props) => ({
    user: selectUserByExternalId(state, props.value?.value?.externalId),
  })),

  withPropsOnChange(
    ['user', 'value'],
    ({ user, value }) => ({
      value: (value && value.value && !value.label) ? ({
        ...value,
        label: user && mapUser(user).label,
      }) : value,
    }),
  ),
)(Autocomplete);
