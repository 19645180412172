import React, { memo } from 'react';
import { compose } from 'recompose';
import PropTypes from 'prop-types';

import { PRODUCT_VIEW } from 'config/constants';

import { withProductPageContext } from 'react/pages/product/productPage/ProductPage.context';

import OverviewPage from './overviewPage/OverviewPage';
import VersionsPage from './versions/VersionsPage';
import FeedbackPage from './feedbackPage/FeedbackPage';
import SuggestionsPage from './suggestionsPage/SuggestionsPage';
import AdditionalInfoPage from './additionalInfoPage/AdditionalInfoPage';

const TAB_RENDERERS_MAP = {
  [PRODUCT_VIEW.OVERVIEW]: OverviewPage,
  [PRODUCT_VIEW.VERSIONS]: VersionsPage,
  [PRODUCT_VIEW.REVIEWS]: FeedbackPage,
  [PRODUCT_VIEW.SUGGESTIONS]: SuggestionsPage,
  [PRODUCT_VIEW.ADDITIONAL_INFORMATION]: AdditionalInfoPage,
};

const enhancer = compose(
  withProductPageContext(),
  memo,
);

const ProductPageTabSwitch = ({
  view,
  isInitiative,
}) => React.createElement(
  (isInitiative && TAB_RENDERERS_MAP[view] === FeedbackPage) ? OverviewPage
    : TAB_RENDERERS_MAP[view] || OverviewPage,
);

ProductPageTabSwitch.displayName = 'ProductPageTabSwitch';

ProductPageTabSwitch.propTypes = {
  view: PropTypes.string,
  isInitiative: PropTypes.bool,
};

ProductPageTabSwitch.defaultProps = {
  view: PRODUCT_VIEW.OVERVIEW,
  isInitiative: false,
};

export default enhancer(ProductPageTabSwitch);
