import React, { memo } from 'react';
import { compose } from 'recompose';
import PropTypes from 'prop-types';
import { noop } from 'lodash';

import { deploymentShape } from 'shapes/deployment';
import { versionShape } from 'shapes/version';
import { moduleShape } from 'shapes/module';
import { teamMemberShape } from 'shapes/product';

import EditDeploymentFormDialog from 'react/business/deployments/form/dialog/edit/EditDeploymentFormDialog';
import DeleteDeploymentFormDialog from 'react/business/deployments/form/dialog/delete/DeleteDeploymentFormDialog';

import LocalReferentsDialog from '../dialog/LocalReferentsDialog';

const enhancer = compose(
  memo,
);

const DialogsContainer = ({
  localReferents,
  canEditProduct,
  deployment,
  version,
  modules,
  isEditDeploymentDialogOpen,
  onCloseEditDeploymentDialog,
  isDeleteDeploymentDialogOpen,
  onCloseDeleteDeploymentDialog,
  isLocalReferentsDialogOpen,
  onCloseLocalReferentsDialog,
}) => (
  <>
    { canEditProduct && (
      <>
        <EditDeploymentFormDialog
          productId={version.product}
          version={version}
          isOpen={isEditDeploymentDialogOpen}
          onRequestClose={onCloseEditDeploymentDialog}
          deployment={deployment}
          modules={modules}
        />
        <DeleteDeploymentFormDialog
          productId={version.product}
          version={version}
          isOpen={isDeleteDeploymentDialogOpen}
          onRequestClose={onCloseDeleteDeploymentDialog}
          deployment={deployment}
        />
      </>
    ) }
    { !!localReferents.length && (
      <LocalReferentsDialog
        teamMembers={localReferents}
        isLocalReferentsDialogOpen={isLocalReferentsDialogOpen}
        onCloseLocalReferentsDialog={onCloseLocalReferentsDialog}
      />
    )}
  </>
);

DialogsContainer.displayName = 'DialogsContainer';

DialogsContainer.propTypes = {
  localReferents: PropTypes.arrayOf(teamMemberShape),
  canEditProduct: PropTypes.bool,
  deployment: deploymentShape,
  version: versionShape,
  modules: PropTypes.arrayOf(moduleShape),
  onCloseEditDeploymentDialog: PropTypes.func,
  isEditDeploymentDialogOpen: PropTypes.bool,
  onCloseDeleteDeploymentDialog: PropTypes.func,
  isDeleteDeploymentDialogOpen: PropTypes.bool,
  onCloseLocalReferentsDialog: PropTypes.func,
  isLocalReferentsDialogOpen: PropTypes.bool,
};

DialogsContainer.defaultProps = {
  localReferents: [],
  modules: [],
  canEditProduct: false,
  deployment: null,
  version: null,
  onCloseEditDeploymentDialog: noop,
  isEditDeploymentDialogOpen: false,
  onCloseDeleteDeploymentDialog: noop,
  isDeleteDeploymentDialogOpen: false,
  onCloseLocalReferentsDialog: noop,
  isLocalReferentsDialogOpen: false,
};

export default enhancer(DialogsContainer);
