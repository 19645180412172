import React, { memo } from 'react';
import { compose, lifecycle } from 'recompose';
import connect from 'react/hoc/connectProxy';
import { formatRoute } from 'react-router-named-routes';
import { withRouter } from 'react-router';
import { Helmet } from 'react-helmet';

import ROUTES from 'react/routes';
import { getCommunities } from 'redux/communities/actions';

import withStepInRoute from 'react/generic/steps/containers/withValueInRoute';

import ProductCreateFormPage from 'react/pages/product/form/create/ProductCreateFormPage';
import FormattedMessageChildren from 'react/generic/i18n/FormattedMessageChildren';

import withSubmitHandlers from '../submit-handlers';

import messages from './product-create-form.messages';

const enhancer = compose(
  memo,
);

const EnhancedProductCreateFormPage = compose(
  withRouter,

  // Step in URL get/set.
  withStepInRoute(
    // Get step from URL.
    ({ match }) => match.params.step,
    // Set step in URL.
    (step, { history }) => history.push(
      formatRoute(ROUTES.PRODUCT.CREATE_FORM.FORM, { step }),
    ),
    // Map value/onChange to step/onChangeStep.
    {
      propsMapper: {
        value: 'step',
        onChange: 'onChangeStep',
      },
      omitRouterProps: false,
    },
  ),

  connect(
    null,
    {
      getCommunities,
    },
  ),

  withSubmitHandlers,

  lifecycle({
    /** Load product on mount. */
    componentDidMount() {
      this.props.getCommunities({ populateDomains: true });
    },
  }),
)(ProductCreateFormPage);

const ProductCreateFormView = () => (
  <>
    <FormattedMessageChildren {...messages.TITLE}>
      {message => (
        <Helmet>
          <title>{message}</title>
        </Helmet>
      )}
    </FormattedMessageChildren>

    <EnhancedProductCreateFormPage />
  </>
);

export default enhancer(ProductCreateFormView);
