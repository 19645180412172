import withSubmitEditVersion from 'react/business/versions/form/withSubmitEditVersion';
import makeVersionFormDialog from '../VersionFormDialog';
import EditFormStep from './steps/Form';
import EditSuccessStep from './steps/Success';
import EditFailureStep from './steps/Failure';

// Handle onSubmit to edit a suggestion.
const SubmitEditFormStep = withSubmitEditVersion(EditFormStep);

export default makeVersionFormDialog(
  SubmitEditFormStep,
  EditSuccessStep,
  EditFailureStep,
);
