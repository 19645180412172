import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { noop, without } from 'lodash';
import { FormattedMessage } from 'react-intl';
import { getFormValues } from 'redux-form';
import {
  compose,
  withPropsOnChange,
  withHandlers,
  branch,
  renderComponent,
} from 'recompose';

import { subsetsShape } from 'shapes/subsets';

import connect from 'react/hoc/connectProxy';
import omitProps from 'react/hoc/omitProps';
import { withProductFormName } from 'react/business/products/form/Context';

import withCommunitySubsets from './connect/withCommunitySubsets.connect';
import SubsetsList from './list/SubsetsList';

import messages from './subset-selector.messages';
import classNames from './subset-selector.module.scss';

const NoCommunityComponent = () => (
  <div className={classNames.noContentContainer}>
    <FormattedMessage {...messages.NO_COMMUNITY} />
  </div>
);

const enhancer = compose(
  withProductFormName(),
  connect(
    (state, props) => ({
      community: getFormValues(props.formName)(state).community,
    }),
  ),

  withPropsOnChange(
    ['community'],
    ({ community }) => ({
      communityId: community?.value,
      communityName: community?.label,
    }),
  ),

  branch(
    ({ communityId }) => !communityId,
    renderComponent(NoCommunityComponent),
  ),

  withCommunitySubsets,

  withHandlers(({
    onToggleElement: ({ value: currentValue, onChange }) => (id, value) => {
      if (value === true) {
        onChange((currentValue || []).concat(id));
      } else {
        onChange(without(currentValue, id));
      }
    },
  })),

  omitProps(['community', 'communityId', 'formName']),

  memo,
);

const SubsetsSelector = ({
  subsets,
  onToggleElement,
  value,
  communityName,
}) => (
  <SubsetsList
    items={subsets}
    onToggleElement={onToggleElement}
    value={value}
    header={communityName}
  />
);

SubsetsSelector.displayName = 'SubsetsSelector';

SubsetsSelector.propTypes = {
  subsets: PropTypes.arrayOf(subsetsShape),
  value: PropTypes.arrayOf(PropTypes.string),
  onToggleElement: PropTypes.func,
  communityName: PropTypes.string,
};

SubsetsSelector.defaultProps = {
  subsets: [],
  value: null,
  onToggleElement: noop,
  communityName: '',
};

export default enhancer(SubsetsSelector);
