import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { branch, compose, renderNothing } from 'recompose';
import { noop } from 'lodash';
import { FormattedMessage } from 'react-intl';
import connect from 'react/hoc/connectProxy';

import { versionShape } from 'shapes/version';
import { PRODUCT_VERSION_STATUS_MAP } from 'config/constants';
import { ICON_MAP } from 'react/generic/asset/Asset';

import { selectCanEditProduct } from 'redux/products/selectors';

import withDialogState from 'react/generic/dialog/withDialogState';
import SafeHtml from 'react/generic/html/SafeHtml';
import EditIconButton from 'react/generic/button/buttons/EditIconButton';
import DeleteIconButton from 'react/generic/button/buttons/DeleteIconButton';

import EditButton from 'react/business/products/editButton/EditButton';
import VersionDeploymentList from 'react/business/deployments/list/VersionDeploymentList';

import CreateDeploymentFormDialog from 'react/business/deployments/form/dialog/create/CreateDeploymentFormDialog';

import EditVersionFormDialog from '../form/dialog/edit/EditVersionFormDialog';
import DeleteVersionFormDialog from '../form/dialog/delete/DeleteVersionFormDialog';

import messages from './version-card.messages';
import classNames from './version-card.module.scss';

const enhancerButton = compose(
  connect((state, props) => ({
    canEditProduct: selectCanEditProduct(state, props.productId),
  })),

  branch(
    ({ canEditProduct }) => !canEditProduct,
    renderNothing,
  ),
);

const EditVersionButton = enhancerButton(EditIconButton);
const DeleteVersionButton = enhancerButton(DeleteIconButton);

const enhancer = compose(
  withDialogState('EditVersion'),
  withDialogState('DeleteVersion'),
  withDialogState('CreateDeployment'),
  memo,
);

const VersionCard = ({
  version,
  productId,
  buInCharge,
  onOpenCreateDeploymentDialog,
  isCreateDeploymentDialogOpen,
  onCloseCreateDeploymentDialog,

  onOpenEditVersionDialog,
  onCloseEditVersionDialog,
  isEditVersionDialogOpen,

  onOpenDeleteVersionDialog,
  onCloseDeleteVersionDialog,
  isDeleteVersionDialogOpen,
}) => (
  <>
    <div className={classNames.container}>
      <div className={classNames.header}>
        <div className={classNames.left}>
          {version.released}
        </div>
        <div className={classNames.centered}>
          <span className={classNames.title}>{version.title}</span>
          {' '}
          <span className={classNames.status}>
            ({PRODUCT_VERSION_STATUS_MAP[version.status]})
          </span>
        </div>
        <div className={classNames.right}>
          <DeleteVersionButton
            onClickDelete={onOpenDeleteVersionDialog}
            productId={version.product}
          />
          <EditVersionButton
            onClickEdit={onOpenEditVersionDialog}
            productId={version.product}
          />
        </div>
      </div>

      <div className={classNames.separator} />

      <div className={classNames.content}>
        <SafeHtml
          unsafeHtml={version.presentation}
        />
      </div>

      <div className={classNames.actions}>
        <EditButton
          productId={version.product}
          onClickEdit={onOpenCreateDeploymentDialog}
          message={<FormattedMessage {...messages.ADD_NEW_BU} />}
          overrideIcon={ICON_MAP.plus}
          outline
        />
      </div>

      <VersionDeploymentList
        version={version}
        versionId={version.id}
        productId={productId}
        buInCharge={buInCharge}
      />
    </div>

    <CreateDeploymentFormDialog
      productId={version.product}
      version={version}
      isOpen={isCreateDeploymentDialogOpen}
      onRequestClose={onCloseCreateDeploymentDialog}
    />

    <EditVersionFormDialog
      productId={version.product}
      version={version}
      isOpen={isEditVersionDialogOpen}
      onRequestClose={onCloseEditVersionDialog}
    />

    <DeleteVersionFormDialog
      productId={version.product}
      version={version}
      isOpen={isDeleteVersionDialogOpen}
      onRequestClose={onCloseDeleteVersionDialog}
    />
  </>
);

VersionCard.displayName = 'VersionCard';

VersionCard.propTypes = {
  version: versionShape.isRequired,
  productId: PropTypes.string,
  buInCharge: PropTypes.string,
  // Create deployment form.
  onOpenCreateDeploymentDialog: PropTypes.func,
  onCloseCreateDeploymentDialog: PropTypes.func,
  isCreateDeploymentDialogOpen: PropTypes.bool,
  // Create deployment form.
  onOpenEditDeploymentDialog: PropTypes.func,
  onCloseEditDeploymentDialog: PropTypes.func,
  isEditDeploymentDialogOpen: PropTypes.bool,
  // Edit form.
  onOpenEditVersionDialog: PropTypes.func,
  onCloseEditVersionDialog: PropTypes.func,
  isEditVersionDialogOpen: PropTypes.bool,
  // Delete form.
  onOpenDeleteVersionDialog: PropTypes.func,
  onCloseDeleteVersionDialog: PropTypes.func,
  isDeleteVersionDialogOpen: PropTypes.bool,
};

VersionCard.defaultProps = {
  productId: '',
  buInCharge: '',

  onOpenCreateDeploymentDialog: noop,
  onCloseCreateDeploymentDialog: noop,
  isCreateDeploymentDialogOpen: false,

  onOpenEditDeploymentDialog: noop,
  onCloseEditDeploymentDialog: noop,
  isEditDeploymentDialogOpen: false,

  onOpenEditVersionDialog: noop,
  onCloseEditVersionDialog: noop,
  isEditVersionDialogOpen: false,

  onOpenDeleteVersionDialog: noop,
  onCloseDeleteVersionDialog: noop,
  isDeleteVersionDialogOpen: false,
};

export default enhancer(VersionCard);
