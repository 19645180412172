import React, { memo } from 'react';
import { compose } from 'recompose';

import { requiredDraftJs } from 'react/generic/form/validators';

import { Field } from 'redux-form';
import { FormattedMessage } from 'react-intl';
import { DebouncedReduxFormWysiwyg } from 'react/generic/form/wysiwyg/Wysiwyg';
import FormattedMessageChildren from 'react/generic/i18n/FormattedMessageChildren';

import classNames from '../fields.module.scss';
import messages from './presentation.messages';

const rules = [requiredDraftJs];

const enhancer = compose(
  memo,
);

const PresentationField = () => (
  <FormattedMessageChildren {...messages.LONG_DESCRIPTION_PLACEHOLDER}>
    {placeholder => (
      <Field
        name="presentation"
        format={null}
        title={<FormattedMessage {...messages.LONG_DESCRIPTION_TITLE} />}
        tooltip={<FormattedMessage {...messages.LONG_DESC_HINT} />}
        component={DebouncedReduxFormWysiwyg}
        validate={rules}
        editorClassName={classNames.presentationEditor}
        placeholder={placeholder}
      />
    )}
  </FormattedMessageChildren>
);

PresentationField.displayName = 'PresentationField';

export default enhancer(PresentationField);
