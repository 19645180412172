import * as VersionService from 'services/versions';
import { ACTIONS } from './constants';

export const GET_PRODUCT_VERSIONS = () => ({
  type: ACTIONS.GET_PRODUCT_VERSIONS,
});

export const GET_PRODUCT_VERSIONS_SUCCESS = (productId, versions) => ({
  type: ACTIONS.GET_PRODUCT_VERSIONS_SUCCESS,
  productId,
  versions,
});

export const GET_PRODUCT_VERSIONS_FAILURE = error => ({
  type: ACTIONS.GET_PRODUCT_VERSIONS_FAILURE,
  error,
});

export const getProductVersions = productId => async (dispatch) => {
  dispatch(GET_PRODUCT_VERSIONS());

  try {
    const versions = await VersionService.getProductVersions(productId);
    return dispatch(GET_PRODUCT_VERSIONS_SUCCESS(productId, versions));
  } catch (err) {
    return dispatch(GET_PRODUCT_VERSIONS_FAILURE(err));
  }
};

export const POST_PRODUCT_VERSION = () => ({
  type: ACTIONS.POST_PRODUCT_VERSION,
});

export const POST_PRODUCT_VERSION_SUCCESS = (productId, version) => ({
  type: ACTIONS.POST_PRODUCT_VERSION_SUCCESS,
  productId,
  version,
});

export const POST_PRODUCT_VERSION_FAILURE = error => ({
  type: ACTIONS.POST_PRODUCT_VERSION_FAILURE,
  error,
});

export const createProductVersion = (productId, version) => async (dispatch) => {
  dispatch(POST_PRODUCT_VERSION());

  try {
    const newVersion = await VersionService.postProductVersion(productId, version);
    return dispatch(POST_PRODUCT_VERSION_SUCCESS(productId, newVersion));
  } catch (err) {
    return dispatch(POST_PRODUCT_VERSION_FAILURE(err));
  }
};

export const PATCH_PRODUCT_VERSION = () => ({
  type: ACTIONS.PATCH_PRODUCT_VERSION,
});

export const PATCH_PRODUCT_VERSION_SUCCESS = (productId, version) => ({
  type: ACTIONS.PATCH_PRODUCT_VERSION_SUCCESS,
  productId,
  version,
});

export const PATCH_PRODUCT_VERSION_FAILURE = error => ({
  type: ACTIONS.PATCH_PRODUCT_VERSION_FAILURE,
  error,
});

export const updateProductVersion = version => async (dispatch) => {
  dispatch(PATCH_PRODUCT_VERSION());

  try {
    const result = await VersionService.patchProductVersion(version);
    return dispatch(PATCH_PRODUCT_VERSION_SUCCESS(version.product, result));
  } catch (err) {
    return dispatch(PATCH_PRODUCT_VERSION_FAILURE(err));
  }
};

export const DELETE_PRODUCT_VERSION = () => ({
  type: ACTIONS.DELETE_PRODUCT_VERSION,
});

export const DELETE_PRODUCT_VERSION_SUCCESS = (productId, versionId) => ({
  type: ACTIONS.DELETE_PRODUCT_VERSION_SUCCESS,
  productId,
  versionId,
});

export const DELETE_PRODUCT_VERSION_FAILURE = error => ({
  type: ACTIONS.DELETE_PRODUCT_VERSION_FAILURE,
  error,
});

export const deleteProductVersion = (productId, versionId) => async (dispatch) => {
  dispatch(DELETE_PRODUCT_VERSION());

  try {
    await VersionService.deleteProductVersion(productId, versionId);
    return dispatch(DELETE_PRODUCT_VERSION_SUCCESS(productId, versionId));
  } catch (err) {
    return dispatch(DELETE_PRODUCT_VERSION_FAILURE(err));
  }
};
