import { compose } from 'recompose';
import connect from 'react/hoc/connectProxy';

import { ACTIONS } from 'redux/versions/constants';
import { createProductVersion } from 'redux/versions/actions';
import { selectPostVersionIsLoading } from 'redux/versions/selectors';

export default compose(
  connect(
    state => ({
      isSubmitting: selectPostVersionIsLoading(state),
    }),
    (dispatch, props) => ({
      onSubmit: async ({ ...version }) => {
        const action = await dispatch(createProductVersion(version.product, version));

        return action.type === ACTIONS.POST_PRODUCT_VERSION_SUCCESS ?
          props.onSubmitSuccess?.(action.version)
          : props.onSubmitFailure?.(action.error);
      },
    }),
  ),
);
