import { defineMessages } from 'react-intl';

export default defineMessages({
  DEPLOYMENT_CREATE_SUCCESS: {
    id: 'deployment.form.create.success.message',
    defaultMessage: 'The new Business unit is now available.',
  },
  DEPLOYMENT_FORM_DIALOG_TITLE: {
    id: 'deployment.form.dialog.create.title',
    defaultMessage: 'Create a Business unit',
  },
});
