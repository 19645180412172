import { compose, lifecycle } from 'recompose';
import connect from 'react/hoc/connectProxy';

import { getProductDeployments } from 'redux/deployments/actions';

export default compose(
  connect(
    null,
    {
      getProductDeployments,
    },
  ),

  lifecycle({
    /**
     * Load deployments on load.
     */
    async componentDidMount() {
      await this.props.getProductDeployments(this.props.productId);
    },
  }),
);
