import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { compose, withHandlers } from 'recompose';
import { noop } from 'lodash';

import { PRODUCT_FORM_STEPS } from 'react/business/products/form/steps/form-steps.constants';

import ProductFormBusinessActivitiesForm from 'react/business/products/form/steps/step-6/BusinessActivitiesForm';
import ProductFormStepLayout from '../layout/StepLayout';

const enhancer = compose(
  withHandlers({
    onClickPreviousStep: ({ onChangeStep }) => () => onChangeStep(
      PRODUCT_FORM_STEPS.LINKS,
    ),

    onClickNextStep: ({ onChangeStep }) => () => onChangeStep(
      PRODUCT_FORM_STEPS.ARCHITECTURE_BUILDING_BLOCKS,
    ),
  }),

  memo,
);

const ProductFormBusinessActivities = ({ onClickPreviousStep, onOpenPreview, onClickNextStep }) => (
  <ProductFormStepLayout
    stepKey={PRODUCT_FORM_STEPS.BUSINESS_ACTIVITIES}
    form={<ProductFormBusinessActivitiesForm />}
    onClickPreviousStep={onClickPreviousStep}
    onClickNextStep={onClickNextStep}
    onOpenPreview={onOpenPreview}
  />
);

ProductFormBusinessActivities.displayName = 'ProductFormBusinessActivities';

ProductFormBusinessActivities.propTypes = {
  onClickPreviousStep: PropTypes.func,
  onClickNextStep: PropTypes.func,
  onOpenPreview: PropTypes.func,
};

ProductFormBusinessActivities.defaultProps = {
  onClickPreviousStep: noop,
  onClickNextStep: noop,
  onOpenPreview: noop,
};

export default enhancer(ProductFormBusinessActivities);
