import { get } from 'lodash';

import StageField from './stage/Stage';
import CommunityField from './community/Community';
import DomainField from './domain/Domain';
import IsMaintainedByPlatformField from './isMaintainedByPlatform/IsMaintainedByPlatform';
import NameField from './name/Name';
import IsGlobalField from './isGlobal/IsGlobal';
import LogoField from './logo/Logo';
import ShortDescriptionField from './shortDescription/ShortDescription';
import PresentationField from './presentation/Presentation';
import HostingTypeField from './hostingType/HostingType';
import MainFeaturesField from './mainFeatures/MainFeatures';
import ScreenshotsField from './screenshots/Screenshots';
import ModulesField from './modules/Modules';
import TagsTechnoField from './tags/techno/Techno';
import TagsDevicesField from './tags/devices/Devices';
import TagsMiscField from './tags/misc/Misc';
import BusinessUnitInChargeField from './businessUnitInCharge/BusinessUnitInCharge';
import TeamMembersField from './teamMembers/TeamMembers';
import BusinessUnitsUsingProductField from './businessUnitsUsingProduct/BusinessUnitsUsingProduct';
import AtomicActivitiesField from './atomicActivities/AtomicActivities';
import LinksField from './links/Links';
import ArchitectureBuildingBlocksField from './architectureBuildingBlocks/ArchitectureBuildingBlocks';
import SubsetsField from './subsets/Subsets';
import SoftwarePublisherField from './softwarePublisher/SoftwarePublisher';
import RejectedReasonField from './validationStatus/RejectedReason';
import RejectedReasonDescriptionField from './validationStatus/RejectedReasonDescription';
import AliasField from './alias/Alias';

const makeSimpleField = field => ({
  getSyncErrorsCount: syncErrors => (get(syncErrors, field.name) ? 1 : 0),
  getAsyncErrorsCount: asyncErrors => (get(asyncErrors, field.name) ? 1 : 0),
  getSyncWarningsCount: syncWarnings => (get(syncWarnings, field.name) ? 1 : 0),
  getAllFieldsNames: () => [field.name],
  ...field,
});

// We do not handle a FieldArray inside a FieldArray yet...
const makeFieldArray = field => ({
  // Count the sum of errors for each Field inside the FieldArray.
  getSyncErrorsCount: syncErrors => (get(syncErrors, field.name) ? 1 : 0),
  getAsyncErrorsCount: asyncErrors => (get(asyncErrors, field.name) ? 1 : 0),
  getSyncWarningsCount: syncWarnings => (get(syncWarnings, field.name) ? 1 : 0),
  ...field,
});

export const stage = makeSimpleField({
  name: 'stage',
  component: StageField,
});

export const community = makeSimpleField({
  name: 'community',
  component: CommunityField,
});

export const domain = makeSimpleField({
  name: 'domain',
  component: DomainField,
});

export const isMaintainedByPlatform = makeSimpleField({
  name: 'isMaintainedByPlatform',
  component: IsMaintainedByPlatformField,
});

export const name = makeSimpleField({
  name: 'name',
  component: NameField,
});

export const alias = makeSimpleField({
  name: 'alias',
  component: AliasField,
});

export const isGlobal = makeSimpleField({
  name: 'isGlobal',
  component: IsGlobalField,
});

export const logo = makeSimpleField({
  name: 'logo',
  component: LogoField,
});

export const shortDescription = makeSimpleField({
  name: 'shortDescription',
  component: ShortDescriptionField,
});

export const presentation = makeSimpleField({
  name: 'presentation',
  component: PresentationField,
});

export const hostingType = makeSimpleField({
  name: 'hostingType',
  component: HostingTypeField,
});

export const mainFeatures = makeFieldArray({
  name: 'mainFeatures',
  component: MainFeaturesField,

  getAllFieldsNames: allValues => allValues?.mainFeatures?.map((_, index) => `mainFeatures[${index}].label`),
});

export const screenshots = makeFieldArray({
  name: 'screenshots',
  component: ScreenshotsField,

  getAllFieldsNames: allValues => allValues?.screenshots?.map((_, index) => `screenshots[${index}].file`),
});

export const modules = makeFieldArray({
  name: 'modules',
  component: ModulesField,

  getAllFieldsNames: allValues => allValues?.modules?.flatMap((_, index) => [
    `modules[${index}].title`,
    `modules[${index}].description`,
  ]),
});

export const tagsTechno = makeSimpleField({
  name: 'tags.techno',
  component: TagsTechnoField,
});

export const tagsDevices = makeSimpleField({
  name: 'tags.devices',
  component: TagsDevicesField,
});

export const tagsMisc = makeSimpleField({
  name: 'tags.misc',
  component: TagsMiscField,
});

export const businessUnitInCharge = makeSimpleField({
  name: 'businessUnitInCharge',
  component: BusinessUnitInChargeField,
});

export const teamMembers = makeFieldArray({
  name: 'teamMembers',
  component: TeamMembersField,

  getAllFieldsNames: allValues => allValues?.teamMembers?.flatMap((_, index) => [
    `teamMembers[${index}].role`,
    `teamMembers[${index}].buReference`,
    `teamMembers[${index}].user`,
  ]),
});

export const businessUnitsUsingProduct = makeSimpleField({
  name: 'businessUnitsUsingProduct',
  component: BusinessUnitsUsingProductField,
});

export const atomicActivities = makeSimpleField({
  name: 'atomicActivities',
  component: AtomicActivitiesField,
});

export const linksProductUrl = makeFieldArray({
  name: 'linksProductUrl',
  component: LinksField,

  getAllFieldsNames: allValues => allValues?.linksProductUrl?.flatMap((_, index) => [
    `linksProductUrl[${index}].title`,
    `linksProductUrl[${index}].url`,
  ]),
});

export const linksProductMarketingUrl = makeFieldArray({
  name: 'linksProductMarketingUrl',
  component: LinksField,

  getAllFieldsNames: allValues => allValues?.linksProductMarketingUrl?.flatMap((_, index) => [
    `linksProductMarketingUrl[${index}].title`,
    `linksProductMarketingUrl[${index}].url`,
  ]),
});

export const linksRoadmap = makeFieldArray({
  name: 'linksRoadmap',
  component: LinksField,

  getAllFieldsNames: allValues => allValues?.linksRoadmap?.flatMap((_, index) => [
    `linksRoadmap[${index}].title`,
    `linksRoadmap[${index}].url`,
  ]),
});

export const linksDocumentation = makeFieldArray({
  name: 'linksDocumentation',
  component: LinksField,

  getAllFieldsNames: allValues => allValues?.linksDocumentation?.flatMap((_, index) => [
    `linksDocumentation[${index}].title`,
    `linksDocumentation[${index}].url`,
  ]),
});

export const linksDiscussion = makeFieldArray({
  name: 'linksDiscussion',
  component: LinksField,

  getAllFieldsNames: allValues => allValues?.linksDiscussion?.flatMap((_, index) => [
    `linksDiscussion[${index}].title`,
    `linksDiscussion[${index}].url`,
  ]),
});

export const linksApiReference = makeFieldArray({
  name: 'linksApiReference',
  component: LinksField,

  getAllFieldsNames: allValues => allValues?.linksApiReference?.flatMap((_, index) => [
    `linksApiReference[${index}].title`,
    `linksApiReference[${index}].url`,
  ]),
});

export const linksSources = makeFieldArray({
  name: 'linksSources',
  component: LinksField,

  getAllFieldsNames: allValues => allValues?.linksSources?.flatMap((_, index) => [
    `linksSources[${index}].title`,
    `linksSources[${index}].url`,
  ]),
});

export const linksBacklog = makeFieldArray({
  name: 'linksBacklog',
  component: LinksField,

  getAllFieldsNames: allValues => allValues?.linksBacklog?.flatMap((_, index) => [
    `linksBacklog[${index}].title`,
    `linksBacklog[${index}].url`,
  ]),
});

export const linksSupport = makeFieldArray({
  name: 'linksSupport',
  component: LinksField,

  getAllFieldsNames: allValues => allValues?.linksSupport?.flatMap((_, index) => [
    `linksSupport[${index}].title`,
    `linksSupport[${index}].url`,
  ]),
});

export const architectureBuildingBlocks = makeSimpleField({
  name: 'architectureBuildingBlocks',
  component: ArchitectureBuildingBlocksField,
});

export const subsets = makeSimpleField({
  name: 'subsets',
  component: SubsetsField,
});

export const softwarePublisher = makeSimpleField({
  name: 'softwarePublisher',
  component: SoftwarePublisherField,
});

export const rejectedReason = makeSimpleField({
  name: 'rejectedReason',
  component: RejectedReasonField,
});
export const rejectedReasonDescription = makeSimpleField({
  name: 'rejectedReason',
  component: RejectedReasonDescriptionField,
});
