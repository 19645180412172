import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import connect from 'react/hoc/connectProxy';
import { compose } from 'recompose';
import { FormattedMessage } from 'react-intl';

import omitProps from 'react/hoc/omitProps';
import { getProduct } from 'redux/products/actions';
import { deleteProductVersion } from 'redux/versions/actions';
import { ACTIONS as VERSION_ACTIONS } from 'redux/versions/constants';

import {
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogAction,
} from 'react/generic/dialog/Dialog';
import { selectDeleteVersionIsLoading } from 'redux/versions/selectors';

import globalMessages from 'config/global.messages';
import messages from '../delete-version-form.messages';

const enhancer = compose(
  memo,

  connect(
    state => ({
      isLoading: selectDeleteVersionIsLoading(state),
    }),
    (dispatch, props) => ({
      onRequestDelete: async () => {
        const action = await dispatch(deleteProductVersion(
          props.productId,
          props.version.id,
        ));

        if (action.type === VERSION_ACTIONS.DELETE_PRODUCT_VERSION_SUCCESS) {
          await dispatch(getProduct(props.productId));
          return props.onDeleteSuccess?.();
        }

        return props.onDeleteFailure?.();
      },
    }),
  ),

  omitProps(['productId', 'version', 'onDeleteSuccess', 'onDeleteFailure']),
);

const DeleteVersionConfirmationDialog = ({ onRequestDelete, isLoading, ...props }) => (
  <>
    <DialogTitle>
      <FormattedMessage {...messages.VERSION_DELETE_FORM_DIALOG_TITLE} />
    </DialogTitle>

    <DialogContent>
      <FormattedMessage {...messages.VERSION_DELETE_FORM_DIALOG_CONTENT} />
    </DialogContent>

    <DialogActions>
      <DialogAction
        outline
        onClick={props.onRequestClose}
      >
        <FormattedMessage {...globalMessages.CANCEL} />
      </DialogAction>

      <DialogAction
        onClick={onRequestDelete}
        disabled={isLoading}
      >
        <FormattedMessage {...globalMessages.CONFIRM} />
      </DialogAction>
    </DialogActions>
  </>
);

DeleteVersionConfirmationDialog.displayName = 'DeleteVersionConfirmationDialog';

DeleteVersionConfirmationDialog.propTypes = {
  onRequestDelete: PropTypes.func,
  onRequestClose: PropTypes.func,
  isLoading: PropTypes.bool,
};

DeleteVersionConfirmationDialog.defaultProps = {
  onRequestDelete: noop,
  onRequestClose: noop,
  isLoading: false,
};

export default enhancer(DeleteVersionConfirmationDialog);
