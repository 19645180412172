import { defineMessages } from 'react-intl';

export default defineMessages({
  REJECTED_REASON_TITLE: { id: 'product.form.fields.rejected.reason.title', defaultMessage: 'Rejected Reason' },

  REJECTED_REASON_HINT: {
    id: 'product.form.fields.rejected.reason.hint',
    defaultMessage: 'Select a reason to reject the product',
  },

  OTHER: {
    id: 'product.form.fields.rejected.reason.other',
    defaultMessage: 'Other',
  },

  INCORRECT_DOMAIN: {
    id: 'product.form.fields.rejected.reason.id',
    defaultMessage: 'Incorrect Domain',
  },

  NA: {
    id: 'product.form.fields.rejected.reason.none',
    defaultMessage: 'N/A',
  },

  REJECTED_REASON_DESC_PLACEHOLDER: {
    id: 'product.form.fields.rejected.reason.desc.placeholder',
    defaultMessage: 'Description Placeholder',
  },

  REJECTED_REASON_DESC_TITLE: {
    id: 'product.form.fields.rejected.reason.desc.title',
    defaultMessage: 'Rejected Reason Description',
  },

  REJECTED_REASON_DESC_HINT: {
    id: 'product.form.fields.rejected.reason.desc.hint',
    defaultMessage: 'Description hint',
  },
});
