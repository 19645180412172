import React, { memo, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { compose, withPropsOnChange } from 'recompose';
import { FormattedMessage } from 'react-intl';
import { isEmpty, noop, uniq } from 'lodash';

import { DEPLOYMENT_BUSINESS_UNITS_DISPLAY_COUNT } from 'config/constants';
import { deploymentShape, versionShape } from 'shapes/version';

import connect from 'react/hoc/connectProxy';

import Button from 'react/generic/button/Button';
import ProductBusinessUnitsList from 'react/business/products/details/businessUnits/list/List';
import connectProductBusinessUnits from 'react/business/products/details/businessUnits/list/connect/businessUnits.connect';

import messages from 'react/business/products/details/businessUnits/product-business-units.messages';
import classNames from 'react/business/products/details/businessUnits/productBusinessUnits.module.scss';

import { selectCanEditProduct } from 'redux/products/selectors';

import withVersionDeployments from '../withVersionDeployments.connect';

const ConnectedProductBusinessUnits = connectProductBusinessUnits(ProductBusinessUnitsList);

const enhancer = compose(
  withVersionDeployments,

  connect((state, props) => ({
    canEditProduct: selectCanEditProduct(state, props.productId),
  })),

  withPropsOnChange(
    ['businessUnitsIds', 'deployments'],
    ({ deployments }) => ({
      businessUnitsIds: uniq((deployments || []).map(d => d.buReference)),
    }),
  ),

  memo,
);

const VersionDeploymentList = ({
  businessUnitsIds,
  version,
  deployments,
  canEditProduct,
  onOpenEditDeploymentDialog,
  productId,
  buInCharge,
}) => {
  const [businessUnitsDisplayCount, setBusinessUnitsDisplayCount]
    = useState(DEPLOYMENT_BUSINESS_UNITS_DISPLAY_COUNT);

  const toggleAllBusinessUnits = useCallback(
    () => {
      const newBusinessUnitsDisplayCount
        = businessUnitsDisplayCount < businessUnitsIds.length ?
          businessUnitsIds.length
          : DEPLOYMENT_BUSINESS_UNITS_DISPLAY_COUNT;
      setBusinessUnitsDisplayCount(newBusinessUnitsDisplayCount);
    },
    [businessUnitsDisplayCount, businessUnitsIds],
  );

  return (
    <>
      { !isEmpty(businessUnitsIds) && (
        <div className={classNames.content}>
          <ConnectedProductBusinessUnits
            businessUnitsIds={businessUnitsIds}
            itemsDisplayCount={businessUnitsDisplayCount}
            deployments={deployments}
            canEditProduct={canEditProduct}
            onOpenEditDeploymentDialog={onOpenEditDeploymentDialog}
            version={version}
            productId={productId}
            buInCharge={buInCharge}
          />
        </div>
      ) }

      { !isEmpty(businessUnitsIds)
        && businessUnitsIds.length > DEPLOYMENT_BUSINESS_UNITS_DISPLAY_COUNT && (
        <div className={classNames.buButtonContainer}>
          <Button
            overrideClassName={classNames.buButton}
            overrideClassNames={classNames}
            onClick={toggleAllBusinessUnits}
          >
            { businessUnitsDisplayCount < businessUnitsIds.length ? (
              <FormattedMessage
                {...messages.SHOW_ALL_BU}
                values={{ count: businessUnitsIds.length }}
              />
            )
              : (
                <FormattedMessage
                  {...messages.HIDE_ALL_BU}
                  values={{ count: businessUnitsIds.length }}
                />
              )
            }
          </Button>
        </div>
      ) }
    </>
  );
};

VersionDeploymentList.displayName = 'VersionDeploymentList';

VersionDeploymentList.propTypes = {
  businessUnitsIds: PropTypes.arrayOf(PropTypes.string),
  deployments: PropTypes.arrayOf(deploymentShape),
  version: versionShape,
  canEditProduct: PropTypes.bool,
  onOpenEditDeploymentDialog: PropTypes.func,
  productId: PropTypes.string,
  buInCharge: PropTypes.string,
};

VersionDeploymentList.defaultProps = {
  businessUnitsIds: [],
  deployments: [],
  version: null,
  canEditProduct: false,
  onOpenEditDeploymentDialog: noop,
  productId: '',
  buInCharge: null,
};

export default enhancer(VersionDeploymentList);
