import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { compose, withHandlers } from 'recompose';
import { noop } from 'lodash';

import { PRODUCT_FORM_STEPS } from 'react/business/products/form/steps/form-steps.constants';
import ProductFormBusinessUnitsForm from 'react/business/products/form/steps/step-4/BusinessUnitsForm';
import ProductFormStepLayout from '../layout/StepLayout';

const enhancer = compose(
  withHandlers({
    onClickPreviousStep: ({ onChangeStep }) => () => onChangeStep(PRODUCT_FORM_STEPS.TEAM),
    onClickNextStep: ({ onChangeStep }) => () => onChangeStep(PRODUCT_FORM_STEPS.LINKS),
  }),

  memo,
);

const ProductFormBusinessUnitsStep = ({ onClickPreviousStep, onOpenPreview, onClickNextStep }) => (
  <ProductFormStepLayout
    stepKey={PRODUCT_FORM_STEPS.BUSINESS_UNITS}
    form={<ProductFormBusinessUnitsForm />}
    onClickPreviousStep={onClickPreviousStep}
    onClickNextStep={onClickNextStep}
    onOpenPreview={onOpenPreview}
  />
);

ProductFormBusinessUnitsStep.displayName = 'ProductFormBusinessUnitsStep';

ProductFormBusinessUnitsStep.propTypes = {
  onClickPreviousStep: PropTypes.func,
  onClickNextStep: PropTypes.func,
  onOpenPreview: PropTypes.func,
};

ProductFormBusinessUnitsStep.defaultProps = {
  onClickPreviousStep: noop,
  onClickNextStep: noop,
  onOpenPreview: noop,
};

export default enhancer(ProductFormBusinessUnitsStep);
