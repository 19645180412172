import React, { memo } from 'react';
import { compose, withProps } from 'recompose';
import { FormattedMessage } from 'react-intl';

import VersionDialogStepSuccess from 'react/business/versions/form/dialog/base/steps/Success';

import messages from '../delete-version-form.messages';

export default compose(
  memo,

  withProps(() => ({
    successMessage: (
      <FormattedMessage {...messages.VERSION_DELETE_SUCCESS} />
    ),
  })),
)(VersionDialogStepSuccess);
