import React from 'react';
import PropTypes from 'prop-types';
import { compose, wrapDisplayName } from 'recompose';
import { getFormValues, isDirty as selectIsDirty } from 'redux-form';

import connect from 'react/hoc/connectProxy';
import { PRODUCT_STAGES } from 'config/constants';

import { Prompt } from 'react-router-dom';
import FormattedMessageChildren from 'react/generic/i18n/FormattedMessageChildren';

import { withProductFormName } from './Context';
import messages from './form.messages';

export const selectIsInitiative
  = formName => state => getFormValues(formName)(state)?.stage === PRODUCT_STAGES.INI;

export const withIsInitiative = (propName = 'isInitiative') => compose(
  withProductFormName(),
  connect(
    (state, props) => ({
      [propName]: selectIsInitiative(props.formName)(state),
    }),
  ),
);

export const withPrompt = (WrappedComponent) => {
  const WithPrompt = ({ isDirty, ...props }) => (
    <>
      <FormattedMessageChildren {...messages.UNSAVED_CHANGES}>
        { message => (
          <Prompt
            when={isDirty}
            message={message}
          />
        ) }
      </FormattedMessageChildren>

      <WrappedComponent {...props} />
    </>
  );

  WithPrompt.displayName = wrapDisplayName(WrappedComponent, 'withPrompt');

  WithPrompt.propTypes = {
    isDirty: PropTypes.bool,
  };

  WithPrompt.defaultProps = {
    isDirty: false,
  };

  return WithPrompt;
};

export const withPreventNavigation = compose(
  withProductFormName(),
  connect(
    (state, { formName }) => ({
      isDirty: selectIsDirty(formName)(state),
    }),
  ),
  withPrompt,
);
