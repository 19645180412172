import { compose } from 'recompose';
import connect from 'react/hoc/connectProxy';

import { ACTIONS } from 'redux/deployments/constants';
import { createProductDeployment } from 'redux/deployments/actions';
import { selectPostDeploymentIsLoading } from 'redux/deployments/selectors';

export default compose(
  connect(
    state => ({
      isSubmitting: selectPostDeploymentIsLoading(state),
    }),
    (dispatch, props) => ({
      onSubmit: async ({ product, ...deployment }) => {
        const action = await dispatch(createProductDeployment(product, deployment));

        return action.type === ACTIONS.POST_PRODUCT_DEPLOYMENT_SUCCESS ?
          props.onSubmitSuccess?.(action.deployment)
          : props.onSubmitFailure?.(action.error);
      },
    }),
  ),
);
