import withSubmitCreateDeployment from 'react/business/deployments/form/withSubmitCreateDeployment';
import makeDeploymentFormDialog from 'react/business/deployments/form/dialog/DeploymentFormDialog';
import CreateSuccessStep from './steps/Success';
import CreateFailureStep from './steps/Failure';
import CreateFormStep from './steps/Form';

// Handle onSubmit to create a deployment.
const SubmitCreateFormStep = withSubmitCreateDeployment(CreateFormStep);

export default makeDeploymentFormDialog(
  SubmitCreateFormStep,
  CreateSuccessStep,
  CreateFailureStep,
);
