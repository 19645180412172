import { compose, withPropsOnChange } from 'recompose';

import connect from 'react/hoc/connectProxy';
import { selectProductVersionsList } from 'redux/versions/selectors';

import Autocomplete from 'react/generic/form/autocomplete/Autocomplete';
import findLabelInOptions from 'react/generic/form/autocomplete/findLabelInOptions';

const mapVersions = version => ({
  value: version.id,
  label: version.title,
});

export default compose(
  connect(
    (state, props) => ({
      versions: selectProductVersionsList(state, props.productId),
    }),
  ),

  withPropsOnChange(
    ['versions'],
    ({ versions }) => ({
      options: versions.map(mapVersions),
    }),
  ),

  findLabelInOptions,
)(Autocomplete);
