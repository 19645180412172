import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { compose, withHandlers } from 'recompose';
import { getFormValues } from 'redux-form';

import connect from 'react/hoc/connectProxy';
import omitProps from 'react/hoc/omitProps';

import { FormattedMessage } from 'react-intl';
import Asset, { ICON_MAP } from 'react/generic/asset/Asset';
import Steps, { Step } from 'react/generic/steps/Steps';

import { PRODUCT_FORM_STEPS } from './form-steps.constants';
import { withProductFormName } from '../Context';
import withFormStepManifest from './withFormStepManifest';

import presentationManifest from './step-1/presentation.manifest';
import featuresManifest from './step-2/features.manifest';
import teamManifest from './step-3/team.manifest';
import businessunitsManifest from './step-4/businessunits.manifest';
import linksManifest from './step-5/links.manifest';
import businessActivitiesFormManifest from './step-6/business-activities-form.manifest';
import architectureBuildingBlocksManifest from './step-7/architectureBuildingBlocks.manifest';
import subsetsManifest from './step-8/subsets.manifest';

import messages from './form-steps.messages';

const stepEnhancer = manifest => compose(
  withFormStepManifest(
    manifest,
    ({ errorsCount, warningsCount }) => ({
      errorsCount,
      warningsCount,
    }),
  ),

  withHandlers({
    onTouch: ({ onTouch }) => value => onTouch?.(value, manifest),
  }),

  omitProps(['formName', 'currentStep']),
);

const PresentationStep = stepEnhancer(presentationManifest)(Step);
const FeaturesStep = stepEnhancer(featuresManifest)(Step);
const TeamStep = stepEnhancer(teamManifest)(Step);
const BusinessUnitsStep = stepEnhancer(businessunitsManifest)(Step);
const LinksStep = stepEnhancer(linksManifest)(Step);
const BusinessActivitiesStep = stepEnhancer(businessActivitiesFormManifest)(Step);
const ArchitectureBuildingBlocksStep = stepEnhancer(architectureBuildingBlocksManifest)(Step);
const SubsetsStep = stepEnhancer(subsetsManifest)(Step);

const enhancer = compose(
  withProductFormName(),

  connect(
    (state, props) => ({
      isGlobal: getFormValues(props.formName)(state)?.isGlobal === 'true',
    }),
  ),

  omitProps(['formName']),

  memo,
);

const ProductFormSteps = ({
  isGlobal,
  ...props
}) => (
  <Steps {...props}>

    <PresentationStep
      value={PRODUCT_FORM_STEPS.PRESENTATION}
      title={<FormattedMessage {...messages.PRESENTATION_TITLE} />}
      subtitle={<FormattedMessage {...messages.PRESENTATION_SUBTITLE} />}
      icon={<Asset name={ICON_MAP.plus} />}
      iconActive={<Asset name={ICON_MAP.check_icon} />}
    />
    <FeaturesStep
      value={PRODUCT_FORM_STEPS.FEATURES}
      title={<FormattedMessage {...messages.FEATURES_TITLE} />}
      subtitle={<FormattedMessage {...messages.FEATURES_SUBTITLE} />}
      icon={<Asset name={ICON_MAP.plus} />}
      iconActive={<Asset name={ICON_MAP.check_icon} />}
    />
    <TeamStep
      value={PRODUCT_FORM_STEPS.TEAM}
      title={<FormattedMessage {...messages.TEAM_TITLE} />}
      subtitle={<FormattedMessage {...messages.TEAM_SUBTITLE} />}
      icon={<Asset name={ICON_MAP.plus} />}
      iconActive={<Asset name={ICON_MAP.check_icon} />}
    />

    {/* Cannot use a Fragment otherwise Steps does not see the Step underneath. */}

    <BusinessUnitsStep
      value={PRODUCT_FORM_STEPS.BUSINESS_UNITS}
      title={<FormattedMessage {...messages.BUSINESS_UNITS_TITLE} />}
      subtitle={<FormattedMessage {...messages.BUSINESS_UNITS_SUBTITLE} />}
      icon={<Asset name={ICON_MAP.plus} />}
      iconActive={<Asset name={ICON_MAP.check_icon} />}
    />

    <LinksStep
      value={PRODUCT_FORM_STEPS.LINKS}
      title={<FormattedMessage {...messages.LINKS_TITLE} />}
      subtitle={<FormattedMessage {...messages.LINKS_SUBTITLE} />}
      icon={<Asset name={ICON_MAP.plus} />}
      iconActive={<Asset name={ICON_MAP.check_icon} />}
    />

    <BusinessActivitiesStep
      value={PRODUCT_FORM_STEPS.BUSINESS_ACTIVITIES}
      title={<FormattedMessage {...messages.BUSINESS_ACTIVITIES_TITLE} />}
      subtitle={<FormattedMessage {...messages.BUSINESS_ACTIVITIES_SUBTITLE} />}
      icon={<Asset name={ICON_MAP.plus} />}
      iconActive={<Asset name={ICON_MAP.check_icon} />}
    />

    <ArchitectureBuildingBlocksStep
      value={PRODUCT_FORM_STEPS.ARCHITECTURE_BUILDING_BLOCKS}
      title={<FormattedMessage {...messages.ARCHITECTURE_BUILDING_BLOCKS_TITLE} />}
      subtitle={<FormattedMessage {...messages.ARCHITECTURE_BUILDING_BLOCKS_SUBTITLE} />}
      icon={<Asset name={ICON_MAP.plus} />}
      iconActive={<Asset name={ICON_MAP.check_icon} />}
    />

    <SubsetsStep
      value={PRODUCT_FORM_STEPS.SUBSETS}
      title={<FormattedMessage {...messages.SUBSETS_TITLE} />}
      subtitle={<FormattedMessage {...messages.SUBSETS_SUBTITLE} />}
      icon={<Asset name={ICON_MAP.plus} />}
      iconActive={<Asset name={ICON_MAP.check_icon} />}
    />

    <Step
      value={PRODUCT_FORM_STEPS.VALIDATION}
      title={<FormattedMessage {...messages.VALIDATION_TITLE} />}
    />
  </Steps>
);

ProductFormSteps.displayName = 'ProductFormSteps';

ProductFormSteps.propTypes = {
  isGlobal: PropTypes.bool,
  isInitiative: PropTypes.bool,
};

ProductFormSteps.defaultProps = {
  isGlobal: false,
  isInitiative: false,
};

export default enhancer(ProductFormSteps);
