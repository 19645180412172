import makeVersionFormDialog from '../VersionFormDialog';
import DeleteFormStep from './steps/Form';
import DeleteSuccessStep from './steps/Success';
import DeleteFailureStep from './steps/Failure';

export default makeVersionFormDialog(
  DeleteFormStep,
  DeleteSuccessStep,
  DeleteFailureStep,
);
