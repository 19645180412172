import { isEmpty } from 'lodash';
import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { compose, withProps } from 'recompose';
import cn from 'classnames';

import { productShape } from 'shapes/product';
import withCompactBreakpoint from 'react/business/responsive/withCompactBreakpoint';

import { FormattedMessage } from 'react-intl';
import Asset, { ICON_MAP } from 'react/generic/asset/Asset';
import Section from 'react/business/products/details/layout/section/Section';
import SectionTitle from 'react/business/products/details/layout/sectionTitle/SectionTitle';
import ScreenshotsGallery from 'react/business/products/sliders/ScreenshotsGallery';
import ProductModules from '../modules/ProductModules';
import ProductFeaturesList from './list/List';

import messages from './product-features.messages';
import classNames from './productFeatures.module.scss';

const enhancer = compose(
  memo,

  withCompactBreakpoint,

  withProps(({ product }) => ({
    hasPictures: !isEmpty(product.screenshots),
  })),
);

const ProductFeatures = ({
  product,
  compact,
  hasPictures,
}) => (
  <Section className={classNames.features}>
    <div
      className={cn(
        classNames.containerFeatures,
        compact && classNames.compact,
      )}
    >
      { (!compact || hasPictures)
        && (
          <section className={classNames.pictures}>
            { hasPictures ? (
              <ScreenshotsGallery
                screenshots={product.screenshots}
                pictureClassName={classNames.screenshot}
              />
            ) : (
              <Asset
                aria-hidden
                name={ICON_MAP.no_screenshot}
                className={classNames.featuresAsset}
              />
            ) }
          </section>
        ) }

      <section className={classNames.featuresList}>
        <div>
          <SectionTitle centered={compact}>
            <FormattedMessage {...messages.FEATURES} />
          </SectionTitle>
          <div>
            <ProductFeaturesList features={product.mainFeatures} />
          </div>
        </div>

        {((product.modules || []).length > 0) && (
          <div className={classNames.moduleContainer}>
            <SectionTitle centered={compact}>
              <FormattedMessage {...messages.MODULES} />
            </SectionTitle>
            <div>
              <ProductModules modules={product.modules} />
            </div>
          </div>
        )}

      </section>
    </div>
  </Section>
);

ProductFeatures.displayName = 'ProductFeatures';

ProductFeatures.propTypes = {
  product: productShape.isRequired,
  compact: PropTypes.bool.isRequired,
  hasPictures: PropTypes.bool.isRequired,
};

export default enhancer(ProductFeatures);
