import { defineMessages } from 'react-intl';

export default defineMessages({
  DEPLOYMENT_DELETE_SUCCESS: {
    id: 'deployment.form.delete.success.message',
    defaultMessage: 'This Business unit has been deleted successfully.',
  },
  DEPLOYMENT_DELETE_FORM_DIALOG_TITLE: {
    id: 'deployment.form.dialog.delete.title',
    defaultMessage: 'Delete a Business unit',
  },
  DEPLOYMENT_DELETE_FORM_DIALOG_CONTENT: {
    id: 'deployment.form.dialog.delete.content',
    defaultMessage: 'Are you sure you want to delete this Business unit?',
  },
});
