import React, { memo, useState } from 'react';
import { compose } from 'recompose';
import PropTypes from 'prop-types';
import cn from 'classnames';
import memoize from 'memoize-one';
import { FormattedMessage } from 'react-intl';

import { userShape } from 'shapes/user';
import { teamMemberShape } from 'shapes/product';
import Button from 'react/generic/button/Button';

import classNames from './list.module.scss';
import ProductTeamMember from './ListItem';
import messages from './list.messages';

const enhancer = compose(
  memo,
);

const filterTeamMembers = (list) => {
  const result = list.reduce((acc, tm) => {
    // TODO: We should not hardcode this. Find a better way.
    if (tm.role.label !== 'Other') {
      if (tm.role?.displayOnTop) {
        acc.displayed[tm.role.label].push(tm);
      } else {
        acc.hidden.push(tm);
      }
    }
    return acc;
  }, {
    displayed: {
      'Business Product Leader': [],
      'Digital Product Leader': [],
      'Product Owner': [],
    },
    hidden: [],
  });

  let orderDisplayed = [];
  Object.entries(result.displayed).forEach((entry) => {
    orderDisplayed = orderDisplayed.concat(entry[1]);
  });

  return { displayed: orderDisplayed, hidden: result.hidden };
};

const memoizedFilterTeamMembers = memoize(filterTeamMembers);

const renderTeamMembers = (teamMembers, users, theme) => (
  <ul className={classNames.team}>
    {teamMembers.map(teamMember => (
      <ProductTeamMember
        key={`${teamMember.externalId}-${teamMember.role?.id}`}
        users={users}
        teamMember={teamMember}
        theme={theme}
      />
    ))}
  </ul>
);

const ProductTeam = ({
  teamMembers,
  users,
  theme,
}) => {
  const [isOpen, setOpen] = useState(false);
  const { displayed, hidden } = memoizedFilterTeamMembers(teamMembers);

  const changeOpen = () => setOpen(!isOpen);

  return (
    <div className={cn(
      classNames.container,
      classNames[theme],
    )}
    >
      {renderTeamMembers(displayed, users)}

      {hidden.length > 0 && (isOpen ?
        (
          <>
            {renderTeamMembers(hidden, users)}
            <div className={classNames.btnContainer}>
              <Button
                outline
                overrideClassName={classNames.seeAllButton}
                overrideClassNames={classNames}
                onClick={changeOpen}
              >
                <FormattedMessage {...messages.HIDE_TEAM} />
              </Button>
            </div>
          </>
        )
        : (
          <div className={classNames.btnContainer}>
            <Button
              outline
              overrideClassName={classNames.seeAllButton}
              overrideClassNames={classNames}
              onClick={changeOpen}
            >
              <FormattedMessage {...messages.SEE_ALL} />
            </Button>
          </div>
        ))}
    </div>
  );
};

ProductTeam.displayName = 'ProductTeam';

ProductTeam.propTypes = {
  teamMembers: PropTypes.arrayOf(teamMemberShape),
  // Users indexed by externalId
  users: PropTypes.objectOf(userShape),
  theme: PropTypes.oneOf(['light', 'dark']),
};

ProductTeam.defaultProps = {
  teamMembers: [],
  users: {},
  theme: 'dark',
};

export default enhancer(ProductTeam);
