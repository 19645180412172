import React, { memo } from 'react';
import PropTypes from 'prop-types';

import { tagShape } from 'shapes/product';
import classNames from './tagList.module.scss';

const TagList = ({ list }) => (
  <ul className={classNames.list}>
    { list.map(element => (
      <li key={element.id}>{element.label}</li>
    )) }
  </ul>
);

TagList.displayName = 'TagList';

TagList.propTypes = {
  list: PropTypes.arrayOf(tagShape),
};

TagList.defaultProps = {
  list: [],
};

export default memo(TagList);
