import React, { memo } from 'react';
import { compose } from 'recompose';
import PropTypes from 'prop-types';

import { productShape } from 'shapes/product';

import { FormattedMessage } from 'react-intl';
import Section from 'react/business/products/details/layout/section/Section';
import SectionTitle from 'react/business/products/details/layout/sectionTitle/SectionTitle';
import TagList from 'react/business/products/tagList/TagList';

import messages from './product-innersource.messages';
import classNames from './productInnersource.module.scss';

const enhancer = compose(
  memo,
);

const ProductInnersource = ({ product }) => (
  <Section className={classNames.innersource}>
    <SectionTitle centered>
      <FormattedMessage {...messages.INNERSOURCE} />
    </SectionTitle>

    <div className={classNames.content}>
      <div className={classNames.list}>
        <h3 className={classNames.listTitle}>
          <FormattedMessage {...messages.TECHNOLOGIES} />
        </h3>

        <TagList list={product.tags?.techno} />
      </div>

      <div className={classNames.list}>
        <h3 className={classNames.listTitle}>
          <FormattedMessage {...messages.DEVICES} />
        </h3>

        <TagList list={product.tags?.devices} />
      </div>

      <div className={classNames.list}>
        <h3 className={classNames.listTitle}>
          <FormattedMessage {...messages.HASHTAGS} />
        </h3>

        <TagList list={product.tags?.misc} />
      </div>
    </div>
  </Section>
);

ProductInnersource.displayName = 'ProductInnersource';

ProductInnersource.propTypes = {
  product: productShape.isRequired,
  isBelowMediumBreakpoint: PropTypes.bool,
};

ProductInnersource.defaultProps = {
  isBelowMediumBreakpoint: false,
};

export default enhancer(ProductInnersource);
