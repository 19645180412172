import { defineMessages } from 'react-intl';

export default defineMessages({
  NAME_TITLE: { id: 'product.form.fields.name.title', defaultMessage: 'Product name *' },

  NAME_HINT: {
    id: 'product.form.fields.name.hint',
    defaultMessage: 'Fill in the official name of your digital product (should be unique).',
  },
});
