import { isEmpty } from 'lodash';
import React, { memo, useState, useCallback } from 'react';
import { compose } from 'recompose';

import { productShape } from 'shapes/product';
import productMessages from 'react/business/products/product.messages';

import { FormattedMessage } from 'react-intl';

import Asset, { ICON_MAP } from 'react/generic/asset/Asset';
import { PRODUCT_BUSINESS_UNITS_DISPLAY_COUNT } from 'config/constants';
import Button from 'react/generic/button/Button';
import Section from 'react/business/products/details/layout/section/Section';
import SectionTitle from 'react/business/products/details/layout/sectionTitle/SectionTitle';

import ProductBusinessUnitsList from './list/List';
import connectProductBusinessUnits from './list/connect/businessUnits.connect';

import messages from './product-business-units.messages';
import classNames from './productBusinessUnits.module.scss';

const ConnectedProductBusinessUnits = connectProductBusinessUnits(ProductBusinessUnitsList);

const enhancer = compose(
  memo,
);

const ProductBusinessUnits = ({ product }) => {
  const [businessUnitsDisplayCount, setBusinessUnitsDisplayCount]
    = useState(PRODUCT_BUSINESS_UNITS_DISPLAY_COUNT);

  const showHideAllBusinessUnits = useCallback(
    () => {
      const newBusinessUnitsDisplayCount
        = businessUnitsDisplayCount < product.businessUnitsUsingProduct.length ?
          product.businessUnitsUsingProduct.length
          : PRODUCT_BUSINESS_UNITS_DISPLAY_COUNT;
      setBusinessUnitsDisplayCount(newBusinessUnitsDisplayCount);
    },
    [businessUnitsDisplayCount, product.businessUnitsUsingProduct],
  );

  return (
    <Section theme="dark">
      <SectionTitle centered>
        <FormattedMessage {...messages.BUSINESS_UNITS} />
        <p className={classNames.subtitle}>
          <FormattedMessage
            {...messages.BUSINESS_UNITS_USING}
            values={{ productName: product.name }}
          />
        </p>
      </SectionTitle>

      { product.isGlobal
        && (
          <>
            <div className={classNames.message}>
              <Asset name={ICON_MAP.world} />
              <div className={classNames.messageText}>
                <FormattedMessage {...productMessages.BU_GLOBAL} />
              </div>
            </div>
          </>
        ) }

      { !isEmpty(product.businessUnitsUsingProduct) && (
        <div className={classNames.content}>
          <ConnectedProductBusinessUnits
            businessUnitsIds={product.businessUnitsUsingProduct}
            itemsDisplayCount={businessUnitsDisplayCount}
            product={product}
            productId={product.id}
            buInCharge={product.businessUnitInCharge}
          />
        </div>
      ) }

      { !isEmpty(product.businessUnitsUsingProduct)
        && product.businessUnitsUsingProduct.length > PRODUCT_BUSINESS_UNITS_DISPLAY_COUNT && (
        <div className={classNames.buButtonContainer}>
          <Button
            outline
            overrideClassName={classNames.buButton}
            overrideClassNames={classNames}
            onClick={showHideAllBusinessUnits}
          >
            { businessUnitsDisplayCount < product.businessUnitsUsingProduct.length ? (
              <FormattedMessage
                {...messages.SHOW_ALL_BU}
                values={{ count: product.businessUnitsUsingProduct.length }}
              />
            )
              : (
                <FormattedMessage
                  {...messages.HIDE_ALL_BU}
                  values={{ count: product.businessUnitsUsingProduct.length }}
                />
              )
            }
          </Button>
        </div>
      ) }

      { !product.isGlobal && isEmpty(product.businessUnitsUsingProduct) && (
        <div className={classNames.message}>
          <div className={classNames.messageText}>
            <FormattedMessage {...messages.ZERO_BU} />
          </div>
        </div>
      ) }
    </Section>
  );
};

ProductBusinessUnits.displayName = 'ProductBusinessUnits';

ProductBusinessUnits.propTypes = {
  product: productShape.isRequired,
};

export default enhancer(ProductBusinessUnits);
