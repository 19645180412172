import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { DatePicker } from 'antd';

import { SelectWithValueSelector as Select } from 'react/generic/select/Select';
import FormattedMessageChildren from 'react/generic/i18n/FormattedMessageChildren';
import Input from 'react/generic/form/input/Input';
import Wysiwyg from 'react/generic/form/wysiwyg/Wysiwyg';

import { PRODUCT_VERSION_STATUS_OPTIONS, MAX_VERSION_TITLE_LENGTH } from 'config/constants';

import messages from './fields.messages';
import classNames from './fields.module.scss';

const enhancer = compose(
  memo,
);

const VersionFormFields = ({
  titleDecorator,
  statusDecorator,
  releasedDecorator,
  descriptionDecorator,
}) => (
  <>
    <div className={classNames.titleContainer}>
      <FormattedMessageChildren {...messages.FIELD_TITLE_PLACEHOLDER}>
        { placeholder => titleDecorator(
          <Input
            placeholder={`${placeholder} *`}
            maxLength={MAX_VERSION_TITLE_LENGTH}
          />,
        ) }
      </FormattedMessageChildren>
    </div>

    <div className={classNames.statusContainer}>
      <FormattedMessageChildren {...messages.FIELD_STATUS_PLACEHOLDER}>
        { placeholder => statusDecorator(
          <Select
            options={PRODUCT_VERSION_STATUS_OPTIONS}
            placeholder={`${placeholder} *`}
          />,
        ) }
      </FormattedMessageChildren>
    </div>

    <div className={classNames.dateContainer}>
      <FormattedMessageChildren {...messages.FIELD_DATE_PLACEHOLDER}>
        { placeholder => releasedDecorator(
          <DatePicker
            placeholder={placeholder}
            disabledTime
          />,
        ) }
      </FormattedMessageChildren>
    </div>

    <div className={classNames.descriptionContainer}>
      <FormattedMessageChildren {...messages.FIELD_PRESENTATION_PLACEHOLDER}>
        { placeholder => descriptionDecorator(
          <Wysiwyg
            editorClassName={classNames.content}
            placeholder={`${placeholder} *`}
          />,
        ) }
      </FormattedMessageChildren>
    </div>
  </>
);

VersionFormFields.displayName = 'VersionFormFields';

VersionFormFields.propTypes = {
  titleDecorator: PropTypes.func.isRequired,
  statusDecorator: PropTypes.func.isRequired,
  releasedDecorator: PropTypes.func.isRequired,
  descriptionDecorator: PropTypes.func.isRequired,
};

export default enhancer(VersionFormFields);
