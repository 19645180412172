import { compose, withHandlers } from 'recompose';
import connect from 'react/hoc/connectProxy';
import { SubmissionError } from 'redux-form';
import { formatRoute } from 'react-router-named-routes';
import swal from 'sweetalert';
import { injectIntl } from 'react-intl';

import ROUTES from 'react/routes';
import { PRODUCT_VIEW } from 'config/constants';
import { ACTIONS } from 'redux/products/constants';
import { createProduct, editProduct } from 'redux/products/actions';
import omitProps from 'react/hoc/omitProps';

import messages from './submit-handlers.messages';

export default compose(
  connect(
    null,
    {
      onCreateProduct: createProduct,
      onEditProduct: editProduct,
    },
  ),

  injectIntl,

  withHandlers({
    onCreateProduct: ({ onCreateProduct }) => async (
      productToCreate,
      formName,
      suppressWarnings,
    ) => {
      const {
        type,
        product,
        invalidFields,
      } = await onCreateProduct(formName, productToCreate, suppressWarnings);

      return {
        success: type === ACTIONS.CREATE_PRODUCT_SUCCESS,
        product,
        invalidFields,
      };
    },

    onEditProduct: ({ onEditProduct }) => async (
      productToEdit,
      formName,
      productId,
      suppressWarnings,
      resendValidation = false,
    ) => {
      const {
        type,
        product,
        invalidFields,
      } = await onEditProduct(
        formName,
        productId,
        productToEdit,
        suppressWarnings,
        resendValidation,
      );

      return {
        success: type === ACTIONS.EDIT_PRODUCT_SUCCESS,
        product,
        invalidFields,
      };
    },

    onSubmitSuccess: ({ history }) => async (product) => {
      // todo change here to not redirect after validation submit

      history.push(formatRoute(
        ROUTES.PRODUCT.PRESENTATION,
        {
          productId: product.id,
          view: PRODUCT_VIEW.OVERVIEW,
        },
      ));
    },

    onSubmitFail: ({ intl }) => async (errors, dispatch, submitError) => {
      if (submitError instanceof SubmissionError) {
        swal(
          intl.formatMessage(messages.ERROR),
          intl.formatMessage(messages.ERROR_HAPPENED),
          'error',
        );
      }
    },
  }),

  omitProps(['intl']),
);
