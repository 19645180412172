import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { compose, withHandlers } from 'recompose';
import { noop } from 'lodash';

import { PRODUCT_FORM_STEPS } from 'react/business/products/form/steps/form-steps.constants';

import ProductFormLinksForm from 'react/business/products/form/steps/step-5/LinksForm';
import ProductFormStepLayout from '../layout/StepLayout';

const enhancer = compose(
  withHandlers({
    onClickPreviousStep: ({ onChangeStep }) => () => onChangeStep(
      PRODUCT_FORM_STEPS.BUSINESS_UNITS,
    ),

    onClickNextStep: ({ onChangeStep }) => () => onChangeStep(
      PRODUCT_FORM_STEPS.BUSINESS_ACTIVITIES,
    ),
  }),

  memo,
);

const ProductFormLinksStep = ({ onClickPreviousStep, onOpenPreview, onClickNextStep }) => (
  <ProductFormStepLayout
    stepKey={PRODUCT_FORM_STEPS.LINKS}
    form={<ProductFormLinksForm />}
    onClickPreviousStep={onClickPreviousStep}
    onClickNextStep={onClickNextStep}
    onOpenPreview={onOpenPreview}
  />
);

ProductFormLinksStep.displayName = 'ProductFormLinksStep';

ProductFormLinksStep.propTypes = {
  onClickPreviousStep: PropTypes.func,
  onClickNextStep: PropTypes.func,
  onOpenPreview: PropTypes.func,
};

ProductFormLinksStep.defaultProps = {
  onClickPreviousStep: noop,
  onClickNextStep: noop,
  onOpenPreview: noop,
};

export default enhancer(ProductFormLinksStep);
