import { noop } from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import {
  compose,
  withStateHandlers,
  withPropsOnChange,
  withHandlers,
} from 'recompose';

import Slider from 'react-slick';
import FsLightbox from 'fslightbox-react';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import FillPicture from 'react/generic/fillPicture/FillPicture';
import omitProps from 'react/hoc/omitProps';

import classNames from './screenshots-gallery.module.scss';

const FillPictureBoundToIndex = compose(
  withHandlers({
    onClick: ({ onClick, index }) => () => onClick(index),
  }),
  omitProps(['index']),
)(FillPicture);

const enhancer = compose(
  withStateHandlers(
    () => ({
      isLightboxOpen: false,
      initialLightboxIndex: 0,
    }),
    {
      onToggleLightbox: ({ isLightboxOpen }) => index => ({
        isLightboxOpen: !isLightboxOpen,
        initialLightboxIndex: index,
      }),
    },
  ),

  withPropsOnChange(
    ['screenshots'],
    ({ screenshots }) => ({
      screenshotsUrls: screenshots.map(screenshot => screenshot.url),
      screenshotsTypes: screenshots.map(screenshot => screenshot.type || 'image'),
    }),
  ),
);

const ScreenshotsGallery = ({
  screenshots,
  pictureClassName,
  pictureStyle,
  isLightboxOpen,
  initialLightboxIndex,
  onToggleLightbox,
  screenshotsUrls,
  screenshotsTypes,
}) => (
  <>
    <Slider
      dots
      infinite
      autoplay
      arrows={false}
      waitForAnimate={false}
      speed={200}
      autoplaySpeed={5000}
      slidesToShow={1}
      slidesToScroll={1}
      className={classNames.carousel}
    >
      { screenshots.map((screenshot, index) => (
        <FillPictureBoundToIndex
          key={screenshot.url}
          src={screenshot.url}
          title={screenshot.alt}
          className={pictureClassName}
          style={pictureStyle}
          index={index + 1}
          onClick={onToggleLightbox}
        />
      )) }
    </Slider>

    <FsLightbox
      toggler={isLightboxOpen}
      slide={initialLightboxIndex}
      urls={screenshotsUrls}
      types={screenshotsTypes}
    />
  </>
);

ScreenshotsGallery.displayName = 'ScreenshotsGallery';

ScreenshotsGallery.propTypes = {
  screenshots: PropTypes.arrayOf(PropTypes.shape({
    url: PropTypes.string,
    alt: PropTypes.string,
  })),
  pictureClassName: PropTypes.string,
  pictureStyle: PropTypes.shape({}),
  isLightboxOpen: PropTypes.bool,
  initialLightboxIndex: PropTypes.number,
  onToggleLightbox: PropTypes.func,
  screenshotsUrls: PropTypes.arrayOf(PropTypes.string),
  screenshotsTypes: PropTypes.arrayOf(PropTypes.string),
};

ScreenshotsGallery.defaultProps = {
  screenshots: [],
  pictureClassName: null,
  pictureStyle: null,
  isLightboxOpen: false,
  initialLightboxIndex: 0,
  onToggleLightbox: noop,
  screenshotsUrls: [],
  screenshotsTypes: [],
};

export default enhancer(ScreenshotsGallery);
