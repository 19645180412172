import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';

import classNames from './fields-group-header.module.scss';

const enhancer = compose(
  memo,
);

const FieldsGroupHeader = ({ title, message, isRequired }) => (
  <div className={classNames.header}>
    <div className={classNames.title}>
      {title}{isRequired ? ' *' : ''}
    </div>
    <div className={classNames.message}>
      {message}
    </div>
  </div>
);

FieldsGroupHeader.displayName = 'FieldsGroupHeader';

FieldsGroupHeader.propTypes = {
  title: PropTypes.node,
  message: PropTypes.node,
  isRequired: PropTypes.bool,
};

FieldsGroupHeader.defaultProps = {
  title: null,
  message: null,
  isRequired: false,
};

export default enhancer(FieldsGroupHeader);
