import { defineMessages } from 'react-intl';

export default defineMessages({
  VERSION_EDIT_SUCCESS: {
    id: 'version.form.edit.success.message',
    defaultMessage: 'This version of your product has been updated successfully.',
  },
  VERSION_EDIT_FORM_DIALOG_TITLE: {
    id: 'version.form.dialog.edit.title',
    defaultMessage: 'Edit a version',
  },
});
