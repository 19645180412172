import React, { memo } from 'react';
import { compose } from 'recompose';
import PropTypes from 'prop-types';
import { noop } from 'lodash';

import EditButton from 'react/business/products/editButton/EditButton';

const enhancer = compose(
  memo,
);

const EditProductButton = ({
  productId,
  labelButton,
  onClickEdit,
}) => (
  <EditButton
    productId={productId}
    onClickEdit={onClickEdit}
    message={labelButton}
  />
);

EditProductButton.displayName = 'EditProductButton';

EditProductButton.propTypes = {
  productId: PropTypes.string,
  onClickEdit: PropTypes.func,
  labelButton: PropTypes.node,
};

EditProductButton.defaultProps = {
  productId: '',
  onClickEdit: noop,
  labelButton: null,
};

export default enhancer(EditProductButton);
