import { defineMessages } from 'react-intl';

export default defineMessages({
  BUSINESS_UNITS_HINT: {
    id: 'pages.product.form.steps.4.hint',
    defaultMessage: 'The Business Units that use your product.',
  },

  HEADER_TITLE_BU: {
    id: 'pages.product.form.step.4.bu.header.title',
    defaultMessage: 'Business Units',
  },
});
