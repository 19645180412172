import React, { memo } from 'react';
import { compose } from 'recompose';

import VersionFormFailureStep from 'react/business/versions/form/base/steps/failure/Failure';
import VersionDialogResultStep from '../ResultStep';

const enhancer = compose(
  memo,
);

const VersionDialogStepFailure = props => (
  <VersionDialogResultStep>
    <VersionFormFailureStep {...props} />
  </VersionDialogResultStep>
);

VersionDialogStepFailure.displayName = 'VersionDialogStepFailure';

export default enhancer(VersionDialogStepFailure);
