import PropTypes from 'prop-types';
import { cidShape } from './product';

export const versionShape = PropTypes.shape({
  id: PropTypes.string,
  title: PropTypes.string,
  presentation: PropTypes.string,
  status: PropTypes.string,
  released: PropTypes.string,
  product: PropTypes.string,
});

export const deploymentShape = PropTypes.shape({
  id: PropTypes.string,
  nickname: PropTypes.string,
  buReference: PropTypes.string,
  version: PropTypes.string,
  modules: PropTypes.arrayOf(PropTypes.string),
  product: PropTypes.string,
  cid: cidShape,
});
