import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { noop } from 'lodash';
import { FormattedMessage } from 'react-intl';

import withDialogState from 'react/generic/dialog/withDialogState';
import ProductSubHeader from 'react/business/products/details/subHeader/ProductSubHeader';
import EditProductButton from 'react/business/products/editButton/editProductButton/EditProductButton';
import VersionCardList from 'react/business/versions/list/VersionCardList';
import CreateVersionFormDialog from 'react/business/versions/form/dialog/create/CreateVersionFormDialog';

import { productShape } from 'shapes/product';
import { versionShape } from 'shapes/version';

import { withProductPageContext } from '../../ProductPage.context';
import withLoadVersions from './withLoadVersions.connect';

import messages from './versions-page.messages';
import classNames from './versions-page.module.scss';

const enhancer = compose(
  withProductPageContext(({ product, canEditProduct }) => ({ product, canEditProduct })),
  withLoadVersions,
  withDialogState('CreateVersion'),
  memo,
);

const VersionsPage = ({
  product,
  versions,
  canEditProduct,
  onOpenCreateVersionDialog,
  onCloseCreateVersionDialog,
  isCreateVersionDialogOpen,
  pageUnderConstruction,
}) => (
  <>
    {pageUnderConstruction && (
      <div className={classNames.container}><div className={classNames.upcomingScreen} /></div>
    )}
    {(!pageUnderConstruction && canEditProduct) && (
      <ProductSubHeader>
        <EditProductButton
          productId={product.id}
          onClickEdit={onOpenCreateVersionDialog}
          labelButton={<FormattedMessage {...messages.ADD_NEW_VERSION} />}
        />
      </ProductSubHeader>
    )}

    {!pageUnderConstruction && (
      <>
        <div className={classNames.container}>
          <VersionCardList
            versions={versions}
            productId={product.id}
            buInCharge={product.businessUnitInCharge}
          />
        </div>
        <CreateVersionFormDialog
          productId={product.id}
          isOpen={isCreateVersionDialogOpen}
          onRequestClose={onCloseCreateVersionDialog}
        />
      </>
    )}
  </>
);

VersionsPage.displayName = 'VersionsPage';

VersionsPage.propTypes = {
  product: productShape.isRequired,
  versions: PropTypes.arrayOf(versionShape),
  canEditProduct: PropTypes.bool,
  isCreateVersionDialogOpen: PropTypes.bool,
  onOpenCreateVersionDialog: PropTypes.func,
  onCloseCreateVersionDialog: PropTypes.func,
  pageUnderConstruction: PropTypes.bool,
};

VersionsPage.defaultProps = {
  versions: [],
  canEditProduct: false,
  isCreateVersionDialogOpen: false,
  onOpenCreateVersionDialog: noop,
  onCloseCreateVersionDialog: noop,
  pageUnderConstruction: true,
};

export default enhancer(VersionsPage);
