import React, { memo } from 'react';
import PropTypes from 'prop-types';
import {
  compose,
  withProps,
} from 'recompose';

import { FORM_MODES } from 'react/business/products/form/form.constants';

import { withProductFormMode } from 'react/business/products/form/Context';
import { withIsInitiative } from 'react/business/products/form/form.utils';

import { FormattedMessage } from 'react-intl';
import FieldsGroup from 'react/generic/form/fieldsGroup/FieldsGroup';
import FieldsGroupHeader from 'react/generic/form/fieldsGroup/header/FieldsGroupHeader';

import { productShape } from 'shapes/product';
import withProductFromFormValues from 'react/pages/product/form/withProductFromFormValues';
import { validationRejectedReasons, validationStatuses } from 'redux/products/constants';
import withCurrentUser from 'react/business/user/withCurrentUser/withCurrentUser.connect';
import { currentUserShape } from 'shapes/user';
import manifest from './presentation.manifest';

import messages from './presentation.messages';

const {
  fields: {
    stage: { component: StageField },
    community: { component: CommunityField },
    domain: { component: DomainField },
    isMaintainedByPlatform: { component: IsMaintainedByPlatformField },
    name: { component: NameField },
    alias: { component: AliasField },
    isGlobal: { component: IsGlobalField },
    logo: { component: LogoField },
    shortDescription: { component: ShortDescriptionField },
    presentation: { component: PresentationField },
    hostingType: { component: HostingTypeField },
    softwarePublisher: { component: SoftwarePublisher },
  },
} = manifest;

const disableCommunityAndDomain = (product, user) => {
  // product is published
  if (product.isDraft === false) {
    return false;
  }
  // product was published before validation flow
  if (Date.parse(product.created) < Date.parse('17 Jan 2022 15:00:00 GMT')) {
    return false;
  }
  // product has no validation data
  if (!product.validation) {
    return false;
  }
  if (product.validation?.status === validationStatuses.RDLV) {
    return false;
  }

  // EDIT AS DOMAIN LEADER
  if (product.validation?.status === validationStatuses.PDL) {
    // On community dropdown select, domainId becomes undefined
    // and blocks domain selection
    if (!product.domainId) return false;

    return !user.domainsWhereUserIsLeader.find(el => el === product.domainId);
  }

  // EDIT AS COMMUNITY LEADER
  if (product.validation?.status === validationStatuses.PCA) {
    // same as above
    if (!product.domainId) return false;
    return !user.communitiesWhereUserIsArchitect.find(el => el === product.communityId);
  }

  if (product.validation?.status === validationStatuses.RBCA) {
    return false;
  }

  return product.validation?.rejectedReason !== validationRejectedReasons.ID;
};

const enhancer = compose(
  withIsInitiative(),
  withProductFormMode(),
  withProps(({ formMode }) => ({
    isInCreateMode: formMode === FORM_MODES.CREATE,
  })),
  withProductFromFormValues,
  withCurrentUser,
  memo,
);

const ProductFormPresentationForm = ({ user: currentUser, product: productFromForm, ...props }) => (
  <>
    <FieldsGroupHeader
      title={<FormattedMessage {...messages.HEADER_TITLE_PRESENTATION} />}
      message={<FormattedMessage {...messages.HEADER_MESSAGE_PRESENTATION} />}
    />

    <StageField {...props} />

    <FieldsGroup inline>
      <CommunityField
        {...props}
        disabled={disableCommunityAndDomain(productFromForm,
          currentUser)}
      />
      <DomainField {...props} disabled={disableCommunityAndDomain(productFromForm, currentUser)} />
    </FieldsGroup>

    <IsMaintainedByPlatformField {...props} />

    <NameField {...props} />

    <AliasField {...props} />

    { !props.isInitiative
      && <IsGlobalField {...props} /> }

    <LogoField {...props} />
    <ShortDescriptionField {...props} />
    <PresentationField {...props} />

    { !props.isInitiative
      && (
        <FieldsGroup inline>
          <SoftwarePublisher {...props} />
          <HostingTypeField {...props} />
        </FieldsGroup>
      ) }
  </>
);

ProductFormPresentationForm.displayName = 'ProductFormPresentationForm';

ProductFormPresentationForm.propTypes = {
  user: currentUserShape.isRequired,
  isInitiative: PropTypes.bool,
  isInCreateMode: PropTypes.bool,
  product: productShape.isRequired,
};

ProductFormPresentationForm.defaultProps = {
  isInitiative: false,
  isInCreateMode: false,
};

export default enhancer(ProductFormPresentationForm);
