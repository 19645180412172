import React from 'react';

import { FormattedMessage } from 'react-intl';
import { productShape } from 'shapes/product';

import { securityShape } from 'shapes/security';
import SafeHtml from 'react/generic/html/SafeHtml';
import { getTimeFromDateString } from 'utils';
import messages from '../form/additional-info-form.messages';
import classNames from '../form/additional-info-form.module.scss';

/**
 * Format ISO dates.
 *
 * @param {string} isoDate - Date.
 * @returns {string} - Date.
 */
function formatDate(isoDate) {
  const dateObj = new Date(isoDate);
  const day = String(dateObj.getUTCDate()).padStart(2, '0');
  const month = String(dateObj.getUTCMonth() + 1).padStart(2, '0'); // Les mois sont 0-indexés
  const year = dateObj.getUTCFullYear();

  return `${day}/${month}/${year}`;
}

const AdditionalInfoPage = ({ product, security }) => (
  <div className={classNames.containerInfos}>
    <div className={classNames.title}>
      <FormattedMessage {...messages.PRODUCT_ADDITIONAL_INFO_FORM_ORGANIZATION} />
    </div>
    <div className={classNames.formRow}>
      <div className={classNames.formLabel}>
        <FormattedMessage {...messages.PRODUCT_ADDITIONAL_INFO_FORM_FIELD_ALIASES} />
      </div>
      <div className={classNames.formField}>
        { product?.aliases?.join(', ') }
      </div>
    </div>
    <div className={classNames.formRow}>
      <div className={classNames.formLabel}>
        <FormattedMessage {...messages.PRODUCT_ADDITIONAL_INFO_FORM_FIELD_INVOICING_MODEL} />
      </div>
      <div className={classNames.formField}>
        { product?.invoicingModel }
      </div>
    </div>
    <div className={classNames.formRow}>
      <div className={classNames.formLabel}>
        <FormattedMessage {...messages.PRODUCT_ADDITIONAL_INFO_FORM_FIELD_GITHUB_PREFIX} />
      </div>
      <div className={classNames.formField}>
        { product?.githubPrefix }
      </div>
    </div>
    <div className={classNames.title}>
      <FormattedMessage {...messages.PRODUCT_ADDITIONAL_INFO_FORM_BUSINESS_IMPACT_ANALYSIS} />
    </div>
    <div className={classNames.formRow}>
      <div className={classNames.formLabel}>
        <FormattedMessage {...messages.PRODUCT_ADDITIONAL_INFO_FORM_FIELD_CIAC} />
      </div>
      <div className={classNames.formField}>
        { product?.cid?.c === 0 ? 0 : product?.cid?.c || 'N/A' }
      </div>
    </div>
    <div className={classNames.formRow}>
      <div className={classNames.formLabel}>
        <FormattedMessage {...messages.PRODUCT_ADDITIONAL_INFO_FORM_FIELD_CIAI} />
      </div>
      <div className={classNames.formField}>
        { product?.cid?.i === 0 ? 0 : product?.cid?.i || 'N/A' }
      </div>
    </div>
    <div className={classNames.formRow}>
      <div className={classNames.formLabel}>
        <FormattedMessage {...messages.PRODUCT_ADDITIONAL_INFO_FORM_FIELD_CIAA} />
      </div>
      <div className={classNames.formField}>
        { product?.cid?.d === 0 ? 0 : product?.cid?.d || 'N/A' }
      </div>
    </div>
    <div className={classNames.formRow}>
      <div className={classNames.formLabel}>
        <FormattedMessage {...messages.PRODUCT_ADDITIONAL_INFO_FORM_FIELD_PERSONAL_DATA} />
      </div>
      <div className={classNames.formField}>
        { product?.personalData }
      </div>
    </div>
    <div className={classNames.formRow}>
      <div className={classNames.formLabel}>
        <FormattedMessage {...messages.PRODUCT_ADDITIONAL_INFO_FORM_FIELD_PSI} />
      </div>
      <div className={classNames.formField}>
        { product?.psi }
      </div>
    </div>

    <div className={classNames.formRow}>
      <div className={classNames.formLabel}>
        <FormattedMessage {...messages.PRODUCT_RECOVERY_TIME_OBJECTIVE_RECOMMENDED} />
      </div>
      { product?.recoveryTimeObjectiveRecommended && (
      <div className={classNames.formField}>
        <FormattedMessage
          {...messages.HOURS}
          values={{ hoursNo: product.recoveryTimeObjectiveRecommended }}
        />
      </div>
      ) }
    </div>

    <div className={classNames.formRow}>
      <div className={classNames.formLabel}>
        <FormattedMessage {...messages.PRODUCT_RECOVERY_POINT_OBJECTIVE_RECOMMENDED} />
      </div>
      { product?.recoveryPointObjectiveRecommended && (
      <div className={classNames.formField}>
        <FormattedMessage
          {...messages.HOURS}
          values={{ hoursNo: product.recoveryPointObjectiveRecommended }}
        />
      </div>
      ) }
    </div>

    <div className={classNames.formRow}>
      <div className={classNames.formLabel}>
        <FormattedMessage {...messages.PRODUCT_ADDITIONAL_INFO_FORM_FIELD_LAST_BIA_DATE} />
      </div>
      <div className={classNames.formField}>
        { security?.lastBIADate ? formatDate(security?.lastBIADate) : '' }
      </div>
    </div>
    <div className={classNames.title}>
      <FormattedMessage {...messages.PRODUCT_OPERATIONS} />
    </div>

    <div className={classNames.formRow}>
      <div className={classNames.formLabel}>
        <FormattedMessage {...messages.PRODUCT_SUPPORT_HOURS_RANGE} />
      </div>
      { product?.supportTimeRange && (
      <div className={classNames.formField}>
        { `${getTimeFromDateString(product?.supportTimeRange?.value)} - `}
        {/* eslint-disable-next-line camelcase */ }
        { getTimeFromDateString(product?.supportTimeRange?.end_value) }
      </div>
      ) }
    </div>

    <div className={classNames.formRow}>
      <div className={classNames.formLabel}>
        <FormattedMessage {...messages.PRODUCT_SUPPORT_DAYS_AVAILABLE} />
      </div>
      <div className={classNames.formField}>
        { product?.supportDaysAvailable.map(
          // eslint-disable-next-line react/no-array-index-key
          (day, index) => <span key={index}>{ day }{ index < product?.supportDaysAvailable.length - 1 && ', ' }</span>,
        ) }
      </div>
    </div>

    <div className={classNames.formRow}>
      <div className={classNames.formLabel}>
        <FormattedMessage {...messages.PRODUCT_RECOVERY_TIME_OBJECTIVE} />
      </div>
      { product?.recoveryTimeObjective && (
      <div className={classNames.formField}>
        <FormattedMessage
          {...messages.HOURS}
          values={{ hoursNo: product.recoveryTimeObjective }}
        />
      </div>
      ) }
    </div>

    <div className={classNames.formRow}>
      <div className={classNames.formLabel}>
        <FormattedMessage {...messages.PRODUCT_RECOVERY_POINT_OBJECTIVE} />
      </div>
      { product?.recoveryPointObjective && (
      <div className={classNames.formField}>
        <FormattedMessage
          {...messages.HOURS}
          values={{ hoursNo: product.recoveryPointObjective }}
        />
      </div>
      ) }
    </div>

    <div className={classNames.formRow}>
      <div className={classNames.formLabel}>
        <FormattedMessage {...messages.PRODUCT_SCHEDULED_MAINTENANCE} />
      </div>
      <div className={classNames.formField}>
        <SafeHtml unsafeHtml={product?.scheduledMaintenanceRange} />
      </div>
    </div>
  </div>
);

AdditionalInfoPage.propTypes = {
  product: productShape,
  security: securityShape,
};

AdditionalInfoPage.defaultProps = {
  product: {},
  security: null,
};

export default AdditionalInfoPage;
