import { defineMessages } from 'react-intl';

export default defineMessages({
  REQUIRED: { id: 'global.form.validation.required', defaultMessage: 'This field is required' },
  MAX_LENGTH: {
    id: 'global.form.validation.maxLength',
    defaultMessage: 'This field cannot be more than {maxLength} {maxLength, plural, one {character} other {characters}} long.',
  },
  EXTERNAL_URL: { id: 'global.form.validation.externalUrl', defaultMessage: 'This URL is invalid.' },
  DPL_REQUIRED: { id: 'global.form.validation.DPLRequired', defaultMessage: 'A Digital Product Leader is required' },
});
