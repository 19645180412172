import React from 'react';
import { FormattedMessage } from 'react-intl';

import { validationRejectedReasons } from 'redux/products/constants';
import messages from './index.messages';

export const required = value => (
  !value ?
    <FormattedMessage {...messages.REQUIRED} />
    : undefined
);

export const requiredDraftJs = editorState => (
  !editorState?.getCurrentContent()?.hasText() ?
    <FormattedMessage {...messages.REQUIRED} />
    : undefined
);

export const maxLength = length => value => (
  value && value.length > length ?
    <FormattedMessage {...messages.MAX_LENGTH} values={{ maxLength: length }} />
    : undefined
);

export const isExternalUrl = value => (
  (value && !/^(http|https):\/\/(.+)\.(.+)/.test(value)) ?
    <FormattedMessage {...messages.EXTERNAL_URL} />
    : undefined
);

export const DPLRequired = (value, product) => {
  const doesntHaveDPL = value && !value.find(member => member && member.user && member.role?.value === '03f0db29-c2f0-433c-853c-6affb8553b95');
  const isArchived = (product.stage === 'ARCH');
  return (
    !isArchived && doesntHaveDPL ?
      <FormattedMessage {...messages.DPL_REQUIRED} />
      : undefined
  );
};

export const requiredRejectedReasonDescription = (value, product) => (
  ((product?.validation?.rejectedReason
    && product?.validation?.rejectedReason !== validationRejectedReasons.NA)
  && !value) ?
    <FormattedMessage {...messages.REQUIRED} />
    : undefined
);
