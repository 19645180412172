import { defineMessages } from 'react-intl';

export default defineMessages({
  // SAVE_DRAFT: { id: 'product.form.layout.header.saveDraft', defaultMessage: 'Save draft' },
  CREATE_PRODUCT: {
    id: 'product.form.layout.header.createTitle',
    defaultMessage: 'Create my product',
  },

  CREATE_INITIATIVE: {
    id: 'product.form.layout.header.createInitiativeTitle',
    defaultMessage: 'Create my initiative',
  },
});
