import React, { memo } from 'react';
import { compose } from 'recompose';
import { FormattedMessage } from 'react-intl';

import FieldsGroupHeader from 'react/generic/form/fieldsGroup/header/FieldsGroupHeader';
import FieldsGroup from 'react/generic/form/fieldsGroup/FieldsGroup';

import NotificationPanel from 'react/generic/form/notificationPanel/NotificationPanel';
import manifest from './team.manifest';

import messages from './team.messages';

const {
  fields: {
    businessUnitInCharge: { component: BusinessUnitInChargeField },
    teamMembers: { component: TeamMembersField },
  },
} = manifest;

const enhancer = compose(
  memo,
);

const ProductFormTeamForm = () => (
  <>
    <NotificationPanel
      type="warning"
      title={<FormattedMessage {...messages.NOTIFICATION_PANEL_TITLE_MCC} />}
      message={<FormattedMessage {...messages.NOTIFICATION_PANEL_MESSAGE_MCC} />}
    />
    <FieldsGroup>
      <FieldsGroupHeader
        title={<FormattedMessage {...messages.HEADER_TITLE_BU_IN_CHARGE} />}
        message={<FormattedMessage {...messages.BUSINESS_UNIT_HINT} />}
        isRequired
      />

      <BusinessUnitInChargeField />
    </FieldsGroup>

    <FieldsGroup>
      <FieldsGroupHeader
        title={<FormattedMessage {...messages.HEADER_TITLE_TEAM} />}
        message={<FormattedMessage {...messages.TEAM_HINT} />}
        isRequired
      />

      <TeamMembersField />
    </FieldsGroup>
  </>
);

export default enhancer(ProductFormTeamForm);
