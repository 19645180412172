import { compose, lifecycle } from 'recompose';

import connect from 'react/hoc/connectProxy';

import { selectProductVersionsList } from 'redux/versions/selectors';
import { getProductVersions } from 'redux/versions/actions';

export default compose(
  connect(
    (state, props) => ({
      versions: selectProductVersionsList(state, props.product.id),
    }),
    {
      getProductVersions,
    },
  ),

  lifecycle({
    /** Load product versions on mount. */
    componentDidMount() {
      if (this.props.product.id) {
        this.props.getProductVersions(this.props.product.id);
      }
    },
  }),
);
