import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { isEmpty } from 'lodash';
import { Tooltip, Popover, Menu } from 'antd';
import uuid from 'uuid/v4';

import { PRODUCT_LINKS, MAX_LINK_TITLE_LENGTH } from 'config/constants';
import { linkShape } from 'shapes/product';

import { linksReferences } from './product-link.constants';
import classNames from './product-link.module.scss';

const createLink = (url, content, rootElementClassName, props) => (
  <a
    {...props}
    href={url}
    target="_blank"
    rel="noopener noreferrer"
    className={rootElementClassName}
  >
    { content }
  </a>
);

const ProductLink = ({
  linkName,
  hideTooltip,
  links,
  className,
  disabled,
  forceActive,
  ...props
}) => {
  const [isMenuDisplayed, setIsMenuDisplayed] = useState(false);
  const filteredLinks = (links || []).filter(({ link, title }) => link || title);

  const handleClick = useCallback(
    () => setIsMenuDisplayed(!isMenuDisplayed),
    [isMenuDisplayed],
  );

  // Get the icon of the requested link.
  const { icon, tooltip, activeClassName } = linksReferences[linkName] || {};
  // If no icon for this link, display nothing.
  if (!icon) { return null; }

  const rootElementClassName = cn(
    className,
    classNames.link,
  );

  let result = (
    <div
      className={cn(
        classNames.iconCircleContainer,
        !!((filteredLinks && filteredLinks.length > 0) || forceActive)
        && (activeClassName || classNames.active),
      )}
    >
      <div className={classNames.iconContainer}>
        {React.cloneElement(
          icon,
          {
            className: cn(icon.props.className, classNames.icon),
          },
        )}
      </div>
    </div>
  );

  if (tooltip && !hideTooltip) {
    result = (
      <Tooltip placement="top" title={tooltip}>
        {result}
      </Tooltip>
    );
  }

  let display = null;

  if (filteredLinks && filteredLinks.length > 0) {
    if (filteredLinks.length > 1) {
      const menu = (
        <Menu
          mode="vertical"
          onClick={handleClick}
          className={classNames.linkMenu}
        >
          {
            filteredLinks.map((link) => {
              let { title } = link;
              if (isEmpty(title)) {
                if (link.link.length > MAX_LINK_TITLE_LENGTH) {
                  title = link.link.substring(0, MAX_LINK_TITLE_LENGTH).concat(' ...');
                } else {
                  title = link.link;
                }
              }
              return (
                <Menu.Item key={uuid()}>
                  { createLink(
                    link.link,
                    title,
                    rootElementClassName,
                    props,
                  ) }
                </Menu.Item>
              );
            })
          }
        </Menu>
      );

      display = (
        <Popover
          content={menu}
          trigger="click"
          visible={isMenuDisplayed}
          onVisibleChange={setIsMenuDisplayed}
          placement="bottom"
          arrowPointAtCenter
          className={rootElementClassName}
        >
          {result}
        </Popover>
      );
    } else {
      display = (
        createLink(
          filteredLinks[0].link,
          result,
          rootElementClassName,
          props,
        )
      );
    }
  } else {
    display = (
      <div {...props} className={rootElementClassName}>
        {result}
      </div>
    );
  }

  return display;
};

ProductLink.displayName = 'ProductLink';

ProductLink.propTypes = {
  /** Link key name. */
  linkName: PropTypes.oneOf(Object.values(PRODUCT_LINKS)).isRequired,
  /** Array of links of the product. */
  links: PropTypes.arrayOf(linkShape),
  /** Root element class name. */
  className: PropTypes.string,
  /** Hide tooltip. */
  hideTooltip: PropTypes.bool,
  /** Clickable or not. */
  disabled: PropTypes.bool,
  /** Force to display as active even if href is falsy. */
  forceActive: PropTypes.bool,
};

ProductLink.defaultProps = {
  links: null,
  className: null,
  hideTooltip: false,
  disabled: false,
  forceActive: false,
};

export default ProductLink;
