import { defineMessages } from 'react-intl';

export default defineMessages({
  VERSION_DELETE_SUCCESS: {
    id: 'version.form.delete.success.message',
    defaultMessage: 'This version of your product has been deleted successfully.',
  },
  VERSION_DELETE_FORM_DIALOG_TITLE: {
    id: 'version.form.dialog.delete.title',
    defaultMessage: 'Delete a version',
  },
  VERSION_DELETE_FORM_DIALOG_CONTENT: {
    id: 'version.form.dialog.delete.content',
    defaultMessage: 'Are you sure you want to delete this version?',
  },
});
