import React, { memo } from 'react';
import { compose } from 'recompose';
import PropTypes from 'prop-types';
import { getFormInitialValues } from 'redux-form';

import { PRODUCT_STAGES } from 'config/constants';
import connect from 'react/hoc/connectProxy';

import Logo from 'react/business/layout/header/logo/Logo';
import { withProductFormName, withProductFormMode } from 'react/business/products/form/Context';
import { FormattedMessage } from 'react-intl';
import { FORM_MODES } from 'react/business/products/form/form.constants';

import messages from './header.messages';
import classNames from './header.module.scss';

const resolveTitleByStage = stage => (stage === PRODUCT_STAGES.INI ?
  <FormattedMessage {...messages.CREATE_INITIATIVE} />
  : <FormattedMessage {...messages.CREATE_PRODUCT} />);

const enhancer = compose(
  memo,

  withProductFormMode(),
  withProductFormName(),

  connect(
    (state, props) => ({
      title: props.formMode === FORM_MODES.UPDATE ?
        getFormInitialValues(props.formName)(state)?.name
        : resolveTitleByStage(getFormInitialValues(props.formName)(state)?.stage),
    }),
  ),
);

const Header = ({
  title,
}) => (
  <header className={classNames.header}>
    <div className={classNames.logoContainer}>
      <Logo />
    </div>

    <h1 className={classNames.title}>{title}</h1>

  </header>
);

Header.displayName = 'Header';

Header.propTypes = {
  title: PropTypes.node,
};

Header.defaultProps = {
  title: null,
};

export default enhancer(Header);
