import React, { memo } from 'react';
import { compose } from 'recompose';
import { FormattedMessage } from 'react-intl';

import FieldsGroupHeader from 'react/generic/form/fieldsGroup/header/FieldsGroupHeader';
import FieldsGroup from 'react/generic/form/fieldsGroup/FieldsGroup';

import manifest from './features.manifest';

import messages from './features.messages';

const {
  fields: {
    mainFeatures: { component: MainFeaturesField },
    screenshots: { component: ScreenshotsField },
    modules: { component: ModulesField },
    tagsTechno: { component: TagsTechnoField },
    tagsDevices: { component: TagsDevicesField },
    tagsMisc: { component: TagsMiscField },
  },
} = manifest;

const enhancer = compose(
  memo,
);

const ProductFormFeaturesForm = () => (
  <>
    <FieldsGroup>
      <FieldsGroupHeader
        title={<FormattedMessage {...messages.HEADER_TITLE_FEATURES} />}
        message={<FormattedMessage {...messages.FEATURES_HINT} />}
      />

      <MainFeaturesField />
    </FieldsGroup>

    <FieldsGroup>
      <FieldsGroupHeader
        title={<FormattedMessage {...messages.HEADER_TITLE_SCREENSHOTS} />}
        message={<FormattedMessage {...messages.SCREENSHOTS_HINT} />}
      />

      <ScreenshotsField />
    </FieldsGroup>

    <FieldsGroup>
      <FieldsGroupHeader
        title={<FormattedMessage {...messages.HEADER_TITLE_MODULES} />}
        message={<FormattedMessage {...messages.MODULES_HINT} />}
      />

      <ModulesField />
    </FieldsGroup>

    <FieldsGroup>
      <FieldsGroupHeader
        title={<FormattedMessage {...messages.HEADER_TITLE_TAGS} />}
        message={<FormattedMessage {...messages.TAGS_HINT} />}
      />

      <TagsTechnoField />
      <TagsDevicesField />
      <TagsMiscField />
    </FieldsGroup>
  </>
);

export default enhancer(ProductFormFeaturesForm);
