import { defineMessages } from 'react-intl';

export default defineMessages({
  ALIAS_TITLE: { id: 'product.form.fields.aliases.title', defaultMessage: 'Product Aliases' },

  ALIAS_HINT: {
    id: 'product.form.fields.aliases.hint',
    defaultMessage: 'Add aliases to your product',
  },
});
