import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { compose, branch, renderComponent } from 'recompose';
import { FormattedMessage } from 'react-intl';

import { versionShape } from 'shapes/version';

import withProductDeployments from 'react/business/deployments/withProductDeployments.connect';

import VersionCard from '../card/VersionCard';

import messages from './version-card-list.messages';
import classNames from './version-card-list.module.scss';

const enhancer = compose(
  withProductDeployments,

  branch(
    props => props.versions.length === 0,
    renderComponent(() => (
      <div className={classNames.empty}>
        <FormattedMessage {...messages.EMPTY_VERSION_LIST} />
      </div>
    )),
  ),

  memo,
);

const VersionCardList = ({ versions, productId, buInCharge }) => (
  versions.map(version => (
    <VersionCard
      version={version}
      key={version.id}
      productId={productId}
      buInCharge={buInCharge}
    />
  ))
);

VersionCardList.displayName = 'VersionCardList';

VersionCardList.propTypes = {
  versions: PropTypes.arrayOf(versionShape),
  productId: PropTypes.string,
  buInCharge: PropTypes.string,
};

VersionCardList.defaultProps = {
  versions: [],
  productId: '',
  buInCharge: '',
};

export default enhancer(VersionCardList);
