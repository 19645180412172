import React, { memo } from 'react';
import { compose } from 'recompose';
import { Field } from 'redux-form';

import { required } from 'react/generic/form/validators';
import BusinessUnitSingleAutocomplete from 'react/business/products/form/autocompletes/businessUnits/BusinessUnitSingle';

const enhancer = compose(
  memo,
);

const rules = [required];

const BusinessUnitInChargeField = () => (
  <Field
    name="businessUnitInCharge"
    component={BusinessUnitSingleAutocomplete}
    validate={rules}
  />
);

BusinessUnitInChargeField.displayName = 'BusinessUnitInChargeField';

export default enhancer(BusinessUnitInChargeField);
