import { defineMessages } from 'react-intl';

export default defineMessages({
  DEPLOYMENT_EDIT_SUCCESS: {
    id: 'deployment.form.edit.success.message',
    defaultMessage: 'This Business unit has been updated successfully.',
  },
  DEPLOYMENT_EDIT_FORM_DIALOG_TITLE: {
    id: 'version.form.dialog.edit.title',
    defaultMessage: 'Edit a Business unit',
  },
});
