import React, { memo } from 'react';
import { compose, withProps } from 'recompose';
import EditableTagGroup from 'react/generic/tags/EditableTagGroup';

import { Field } from 'redux-form';
import { FormattedMessage } from 'react-intl';
import classNames from '../fields.module.scss';
import { withIsInitiative } from '../../form.utils';
import { withProductFormMode } from '../../Context';
import { FORM_MODES } from '../../form.constants';
import messages from './alias.messages';

import CustomField from '../../../../../generic/form/field/Field';

const enhancer = compose(
  withIsInitiative(),
  withProductFormMode(),
  withProps(({ formMode }) => ({
    isInCreateMode: formMode === FORM_MODES.CREATE,
  })),
  memo,
);

const Alias = (inputProp) => {
  const { input: { value, onChange }, title, tooltip } = inputProp;
  const sentValue = (typeof value === 'string') ? value.split(' ').filter(el => el) : value;
  return (
    <CustomField title={title} tooltip={tooltip}>
      <div className={classNames.aliasField}>
        <EditableTagGroup
          value={sentValue}
          onChange={onChange}
        />
      </div>
    </CustomField>
  );
};

const AliasField = () => (
  <Field
    type="text"
    name="aliases"
    title={<FormattedMessage {...messages.ALIAS_TITLE} />}
    tooltip={<FormattedMessage {...messages.ALIAS_HINT} />}
    component={Alias}
  />
);

AliasField.displayName = 'AliasField';

export default enhancer(AliasField);
