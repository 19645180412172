import * as DeploymentService from 'services/deployments';
import { ACTIONS } from './constants';

export const GET_PRODUCT_DEPLOYMENTS = () => ({
  type: ACTIONS.GET_PRODUCT_DEPLOYMENTS,
});

export const GET_PRODUCT_DEPLOYMENTS_SUCCESS = (productId, deployments) => ({
  type: ACTIONS.GET_PRODUCT_DEPLOYMENTS_SUCCESS,
  productId,
  deployments,
});

export const GET_PRODUCT_DEPLOYMENTS_FAILURE = error => ({
  type: ACTIONS.GET_PRODUCT_DEPLOYMENTS_FAILURE,
  error,
});

export const getProductDeployments = productId => async (dispatch) => {
  dispatch(GET_PRODUCT_DEPLOYMENTS());

  try {
    const deployments = await DeploymentService.getProductDeployments(productId);
    return dispatch(GET_PRODUCT_DEPLOYMENTS_SUCCESS(productId, deployments));
  } catch (err) {
    return dispatch(GET_PRODUCT_DEPLOYMENTS_FAILURE(err));
  }
};

export const POST_PRODUCT_DEPLOYMENT = () => ({
  type: ACTIONS.POST_PRODUCT_DEPLOYMENT,
});

export const POST_PRODUCT_DEPLOYMENT_SUCCESS = (productId, deployment) => ({
  type: ACTIONS.POST_PRODUCT_DEPLOYMENT_SUCCESS,
  productId,
  deployment,
});

export const POST_PRODUCT_DEPLOYMENT_FAILURE = error => ({
  type: ACTIONS.POST_PRODUCT_DEPLOYMENT_FAILURE,
  error,
});

export const createProductDeployment = (productId, deployment) => async (dispatch) => {
  dispatch(POST_PRODUCT_DEPLOYMENT());

  try {
    const newDeployment = await DeploymentService.postProductDeployment(productId, deployment);
    return dispatch(POST_PRODUCT_DEPLOYMENT_SUCCESS(productId, newDeployment));
  } catch (err) {
    return dispatch(POST_PRODUCT_DEPLOYMENT_FAILURE(err));
  }
};

export const PATCH_PRODUCT_DEPLOYMENT = () => ({
  type: ACTIONS.PATCH_PRODUCT_DEPLOYMENT,
});

export const PATCH_PRODUCT_DEPLOYMENT_SUCCESS = (productId, deployment) => ({
  type: ACTIONS.PATCH_PRODUCT_DEPLOYMENT_SUCCESS,
  productId,
  deployment,
});

export const PATCH_PRODUCT_DEPLOYMENT_FAILURE = error => ({
  type: ACTIONS.PATCH_PRODUCT_DEPLOYMENT_FAILURE,
  error,
});

export const updateProductDeployment = deployment => async (dispatch) => {
  dispatch(PATCH_PRODUCT_DEPLOYMENT());

  try {
    const result = await DeploymentService.patchProductDeployment(deployment.product, deployment);
    return dispatch(PATCH_PRODUCT_DEPLOYMENT_SUCCESS(deployment.product, result));
  } catch (err) {
    return dispatch(PATCH_PRODUCT_DEPLOYMENT_FAILURE(err));
  }
};

export const DELETE_PRODUCT_DEPLOYMENT = () => ({
  type: ACTIONS.DELETE_PRODUCT_DEPLOYMENT,
});

export const DELETE_PRODUCT_DEPLOYMENT_SUCCESS = (productId, versionId, deploymentId) => ({
  type: ACTIONS.DELETE_PRODUCT_DEPLOYMENT_SUCCESS,
  productId,
  versionId,
  deploymentId,
});

export const DELETE_PRODUCT_DEPLOYMENT_FAILURE = error => ({
  type: ACTIONS.DELETE_PRODUCT_DEPLOYMENT_FAILURE,
  error,
});

export const deleteProductDeployment = (productId, versionId, deploymentId) => async (dispatch) => {
  dispatch(DELETE_PRODUCT_DEPLOYMENT());

  try {
    await DeploymentService.deleteProductDeployment(productId, deploymentId);
    return dispatch(DELETE_PRODUCT_DEPLOYMENT_SUCCESS(productId, versionId, deploymentId));
  } catch (err) {
    return dispatch(DELETE_PRODUCT_DEPLOYMENT_FAILURE(err));
  }
};

