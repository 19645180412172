import { defineMessages } from 'react-intl';

export default defineMessages({
  PRESENTATION: { id: 'product.titles.presentation', defaultMessage: 'Presentation' },
  TAGS: { id: 'product.titles.tags', defaultMessage: 'Tags' },
  BUSINESS_ACTIVITIES: { id: 'product.titles.businessActivities', defaultMessage: 'Business activities' },
  REVIEWS: { id: 'product.titles.reviews', defaultMessage: 'Feedbacks' },
  SUGGESTIONS: { id: 'product.titles.suggestions', defaultMessage: 'Feature suggestions' },
  EDIT_PRODUCT_BUTTON: { id: 'product.titles.editProductButton', defaultMessage: 'Edit the product overview' },
  EDIT_ADDITIONAL_BUTTON: { id: 'product.titles.editAdditionalInfoButton', defaultMessage: 'Edit product additional information' },
  EDIT_BUSINESS_VALUES: { id: 'product.titles.editBusinessValues', defaultMessage: 'Edit product business value' },
  SEE_ALL_REVIEWS: { id: 'product.actions.seeAllReviews', defaultMessage: 'See all feedbacks' },
  COMPLETION_RATE: { id: 'product.completion.rate', defaultMessage: 'Completion rate { completionRate } %' },
  LAST_UPDATE: { id: 'product.lastUpdate', defaultMessage: 'Last update: ' },
  COMPLETION_RATE_TOOLTIP: { id: 'product.completion.rate.tooltip', defaultMessage: 'To understand, {link} ?' },
  COMPLETION_RATE_LINK: { id: 'product.completion.rate.tooltip.here', defaultMessage: 'how is the completion rate calculated' },
});
