import agent from 'services/http';
import { mapVersionFromServer } from './versions.mapper';

const e = encodeURIComponent;

/**
 * GET /api/products/:productId/versions.
 * Get the list of versions of products.
 *
 * @async
 * @param {string} productId - Product id.
 * @returns {{versions: Array<object>, count: number}} Results.
 */
export async function getProductVersions(productId) {
  const { body } = await agent
    .get(`/api/products/${e(productId)}/versions`);

  return (body || []).map(mapVersionFromServer);
}

/**
 * POST /api/products/:productId/versions.
 * Create a new version on a product.
 *
 * @async
 * @param {string} productId - Product id.
 * @param {object} version - Version to create.
 * @returns {object} Created version.
 */
export async function postProductVersion(productId, version) {
  const { body } = await agent
    .post(`/api/products/${e(productId)}/versions`)
    .send(version);

  return mapVersionFromServer(body);
}

/**
 * PATCH /api/products/:productId/versions/:versionId.
 * Update a existing version.
 *
 * @async
 * @param {object} version - Version to update.
 * @returns {object} Updated version.
 */
export async function patchProductVersion(version) {
  const { body } = await agent
    .patch(`/api/products/${e(version.product)}/versions/${e(version.id)}`)
    .send(version);

  return mapVersionFromServer(body);
}

/**
 * DELETE /api/products/:productId/versions/:versionId.
 * Delete a version on a product.
 *
 * @async
 * @param {string} productId - Product id.
 * @param {string} versionId - Version id.
 * @returns {object} Http response body.
 */
export async function deleteProductVersion(productId, versionId) {
  const { body } = await agent
    .delete(`/api/products/${e(productId)}/versions/${e(versionId)}`);

  return body;
}
