import { compose, withProps, withPropsOnChange } from 'recompose';

import connect from 'react/hoc/connectProxy';
import { selectProductById } from 'redux/products/selectors';

import Autocomplete from 'react/generic/form/autocomplete/Autocomplete';
import findLabelInOptions from 'react/generic/form/autocomplete/findLabelInOptions';

const mapModules = module => ({
  value: module.id,
  label: module.title,
});

export default compose(
  connect(
    (state, props) => ({
      modules: selectProductById(state, props.productId).modules,
    }),
  ),

  withProps({
    isMulti: true,
  }),

  withPropsOnChange(
    ['modules'],
    ({ modules }) => ({
      options: modules.map(mapModules),
    }),
  ),

  findLabelInOptions,
)(Autocomplete);
