import { compose } from 'recompose';
import connect from 'react/hoc/connectProxy';

import { ACTIONS } from 'redux/deployments/constants';
import { updateProductDeployment } from 'redux/deployments/actions';
import { selectPatchDeploymentIsLoading } from 'redux/deployments/selectors';

export default compose(
  connect(
    state => ({
      isSubmitting: selectPatchDeploymentIsLoading(state),
    }),
    (dispatch, props) => ({
      onSubmit: async ({ ...deployment }) => {
        const deploymentToUpdate = {
          ...deployment,
          id: props.deployment.id,
        };

        const action = await dispatch(updateProductDeployment(deploymentToUpdate));

        return action.type === ACTIONS.PATCH_PRODUCT_DEPLOYMENT_SUCCESS ?
          props.onSubmitSuccess?.(action.deployment)
          : props.onSubmitFailure?.(action.error);
      },
    }),
  ),
);
