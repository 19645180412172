import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import connect from 'react/hoc/connectProxy';
import { compose } from 'recompose';
import { FormattedMessage } from 'react-intl';

import omitProps from 'react/hoc/omitProps';
import { getProduct } from 'redux/products/actions';
import { deleteProductDeployment } from 'redux/deployments/actions';
import { ACTIONS as DEPLOYMENT_ACTIONS } from 'redux/deployments/constants';
import { selectDeleteDeploymentIsLoading } from 'redux/deployments/selectors';

import {
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogAction,
} from 'react/generic/dialog/Dialog';

import globalMessages from 'config/global.messages';
import messages from '../delete-deployment-form.messages';

const enhancer = compose(
  memo,

  connect(
    state => ({
      isLoading: selectDeleteDeploymentIsLoading(state),
    }),
    (dispatch, props) => ({
      onRequestDelete: async () => {
        if (!props.isLoading) {
          const action = await dispatch(deleteProductDeployment(
            props.productId,
            props.version.value,
            props.deployment.id,
          ));

          if (action.type === DEPLOYMENT_ACTIONS.DELETE_PRODUCT_DEPLOYMENT_SUCCESS) {
            await dispatch(getProduct(props.productId));
            return props.onDeleteSuccess?.();
          }

          return props.onDeleteFailure?.();
        }
        return null;
      },
    }),
  ),

  omitProps(['productId', 'version', 'onDeleteSuccess', 'onDeleteFailure']),
);

const DeleteDeploymentConfirmationDialog = ({ onRequestDelete, isLoading, ...props }) => (
  <>
    <DialogTitle>
      <FormattedMessage {...messages.DEPLOYMENT_DELETE_FORM_DIALOG_TITLE} />
    </DialogTitle>

    <DialogContent>
      <FormattedMessage {...messages.DEPLOYMENT_DELETE_FORM_DIALOG_CONTENT} />
    </DialogContent>

    <DialogActions>
      <DialogAction
        outline
        onClick={props.onRequestClose}
      >
        <FormattedMessage {...globalMessages.CANCEL} />
      </DialogAction>

      <DialogAction
        onClick={onRequestDelete}
        disabled={isLoading}
      >
        <FormattedMessage {...globalMessages.CONFIRM} />
      </DialogAction>
    </DialogActions>
  </>
);

DeleteDeploymentConfirmationDialog.displayName = 'DeleteDeploymentConfirmationDialog';

DeleteDeploymentConfirmationDialog.propTypes = {
  onRequestDelete: PropTypes.func,
  onRequestClose: PropTypes.func,
  isLoading: PropTypes.bool,
};

DeleteDeploymentConfirmationDialog.defaultProps = {
  onRequestDelete: noop,
  onRequestClose: noop,
  isLoading: false,
};

export default enhancer(DeleteDeploymentConfirmationDialog);
