import { defineMessages } from 'react-intl';

export default defineMessages({
  FACET_COMMUNITIES: { id: 'facets.communities', defaultMessage: 'Platforms' },
  FACET_BUSINESS_UNITS_USERS: { id: 'facets.businessUnits.users', defaultMessage: 'Business Units users' },
  FACET_BUSINESS_UNIT_OWNERS: { id: 'facets.businessUnits.owners', defaultMessage: 'Business Units owners' },
  FACET_COUNTRIES: { id: 'facets.countries', defaultMessage: 'Countries' },
  FACET_BRANDS: { id: 'facets.brands', defaultMessage: 'Brands' },
  FACET_STAGES: { id: 'facets.stages ', defaultMessage: 'Stages' },
  FACET_BUSINESS_ACTIVITIES: { id: 'facets.businessActivities', defaultMessage: 'Business Activities' },
  FACET_ARCHITECTURE_BUILDING_BLOCKS: { id: 'facets.architectureBuildingBlocks', defaultMessage: 'Architecture Building Blocks' },
  FACET_SUBSETS: { id: 'facets.subsets', defaultMessage: 'Subsets' },
  FACET_MAINTAINED_BY_PLATFORM: { id: 'facets.maintainedByPlatform', defaultMessage: 'Products' },
  SORTING_OPTIONS: { id: 'sorting.label', defaultMessage: 'Sort by' },
});
