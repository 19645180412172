import React, { createContext } from 'react';

export const ProductPageContext = createContext({
  product: null,
  security: null,
  isInitiative: false,
  canEditProduct: false,
  isPreview: false,
});

const defaultMapContextToProps = ({
  product,
  security,
  isInitiative,
  canEditProduct,
  isPreview,
}) => ({
  product,
  security,
  isInitiative,
  canEditProduct,
  isPreview,
});

export const withProductPageContext = (
  mapContextToProps = defaultMapContextToProps,
) => WrappedComponent => props => (
  <ProductPageContext.Consumer>
    {context => <WrappedComponent {...props} {...mapContextToProps(context)} />}
  </ProductPageContext.Consumer>
);
