import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { compose, withHandlers } from 'recompose';
import { noop } from 'lodash';

import { PRODUCT_FORM_STEPS } from 'react/business/products/form/steps/form-steps.constants';

import ProductFormSubsetsForm from 'react/business/products/form/steps/step-8/SubsetsForm';
import ProductFormStepLayout from '../layout/StepLayout';

const enhancer = compose(
  withHandlers({
    onClickPreviousStep: ({ onChangeStep }) => () => onChangeStep(
      PRODUCT_FORM_STEPS.ARCHITECTURE_BUILDING_BLOCKS,
    ),

    onClickNextStep: ({ onChangeStep }) => () => onChangeStep(PRODUCT_FORM_STEPS.VALIDATION),
  }),

  memo,
);

const ProductFormSubsets = ({
  onClickPreviousStep,
  onOpenPreview,
  onClickNextStep,
}) => (
  <ProductFormStepLayout
    stepKey={PRODUCT_FORM_STEPS.SUBSETS}
    form={<ProductFormSubsetsForm />}
    onClickPreviousStep={onClickPreviousStep}
    onClickNextStep={onClickNextStep}
    onOpenPreview={onOpenPreview}
  />
);

ProductFormSubsets.displayName = 'ProductFormSubsets';

ProductFormSubsets.propTypes = {
  onClickPreviousStep: PropTypes.func,
  onClickNextStep: PropTypes.func,
  onOpenPreview: PropTypes.func,
};

ProductFormSubsets.defaultProps = {
  onClickPreviousStep: noop,
  onClickNextStep: noop,
  onOpenPreview: noop,
};

export default enhancer(ProductFormSubsets);
