import React, { memo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import { compose } from 'recompose';

import globalMessages from 'config/global.messages';

import { FormattedMessage } from 'react-intl';
import Button from 'react/generic/button/Button';
import VersionFormSuccessStep from 'react/business/versions/form/base/steps/success/Success';
import VersionDialogResultStep from '../ResultStep';

const enhancer = compose(
  memo,
);

const VersionDialogStepSuccess = ({
  successMessage,
  onClickClose,
}) => {
  const renderAction = useCallback(
    ({ className }) => (
      <Button
        className={className}
        onClick={onClickClose}
      >
        <FormattedMessage {...globalMessages.CLOSE} />
      </Button>
    ),
    [onClickClose],
  );

  return (
    <VersionDialogResultStep>
      <VersionFormSuccessStep
        successMessage={successMessage}
        renderAction={renderAction}
      />
    </VersionDialogResultStep>
  );
};

VersionDialogStepSuccess.displayName = 'VersionDialogStepSuccess';

VersionDialogStepSuccess.propTypes = {
  successMessage: PropTypes.node.isRequired,
  onClickClose: PropTypes.func,
};

VersionDialogStepSuccess.defaultProps = {
  onClickClose: noop,
};

export default enhancer(VersionDialogStepSuccess);
