import { compose } from 'recompose';
import connect from 'react/hoc/connectProxy';

import { selectDeploymentListByVersionId } from 'redux/deployments/selectors';

export default compose(
  connect(
    (state, props) => ({
      deployments: selectDeploymentListByVersionId(state, props.productId, props.versionId),
    }),
  ),
);
