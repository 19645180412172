import withSubmitEditDeployment from 'react/business/deployments/form/withSubmitEditDeployment';
import makeDeploymentFormDialog from 'react/business/deployments/form/dialog/DeploymentFormDialog';
import EditSuccessStep from './steps/Success';
import EditFailureStep from './steps/Failure';
import EditFormStep from './steps/Form';

// Handle onSubmit to create a deployment.
const SubmitEditFormStep = withSubmitEditDeployment(EditFormStep);

export default makeDeploymentFormDialog(
  SubmitEditFormStep,
  EditSuccessStep,
  EditFailureStep,
);
