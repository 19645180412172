import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import { compose, withHandlers, renameProp } from 'recompose';

import omitProps from 'react/hoc/omitProps';
import { deploymentShape } from 'shapes/deployment';
import { versionShape } from 'shapes/version';

import Dialog from 'react/generic/dialog/Dialog';
import withFormSteps from 'react/generic/form/withFormSteps';

import classNames from './deployment-form-dialog.module.scss';

export default (
  FormStep,
  SuccessStep,
  FailureStep,
) => {
  const FormWithSteps = compose(
  // Add form steps state and goToX step handlers.
    withFormSteps(
      ({ onRequestClose }) => (<SuccessStep onClickClose={onRequestClose} />),
      ({ onGoToFormStep }) => (<FailureStep onClickTryAgain={onGoToFormStep} />),
    ),

    // Override parent onSubmitSuccess to also change the step of the form.
    withHandlers({
      onSubmitSuccess: ({ onGoToSuccessStep, onSubmitSuccess }) => () => {
        onGoToSuccessStep();
        return onSubmitSuccess?.();
      },
    }),

    // Do not expose onGoToSuccessStep (overriden above).
    omitProps(['onGoToSuccessStep']),
    // Add onSubmitFailure method to redirect to the failure step.
    renameProp('onGoToFailureStep', 'onSubmitFailure'),
  )(FormStep);

  const enhancer = compose(
    memo,
  );

  const DeploymentFormDialog = ({
    productId,
    version,
    onSubmitSuccess,
    deployment,
    ...props
  }) => {
    const defaultVersion = {
      value: version.id,
      label: version.title,
    };

    return (
      <Dialog
        {...props}
        contentClassName={classNames.dialog}
      >
        <FormWithSteps
          productId={productId}
          version={defaultVersion}
          onSubmitSuccess={onSubmitSuccess}
          onRequestClose={props.onRequestClose}
          deployment={deployment}
        />
      </Dialog>
    );
  };

  DeploymentFormDialog.displayName = 'DeploymentFormDialog';

  DeploymentFormDialog.propTypes = {
    productId: PropTypes.string,
    versionId: PropTypes.string,
    version: versionShape,
    onSubmitSuccess: PropTypes.func,
    onRequestClose: PropTypes.func,
    deployment: deploymentShape,
  };

  DeploymentFormDialog.defaultProps = {
    productId: '',
    versionId: '',
    version: {},
    onSubmitSuccess: noop,
    onRequestClose: noop,
    deployment: null,
  };

  return enhancer(DeploymentFormDialog);
};
