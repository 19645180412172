import React, { memo } from 'react';
import { compose } from 'recompose';

import { hostingTypesInfoLink, hostingTypesOptions } from 'react/business/products/hostingTypes/hostingTypes.constants';

import { Field } from 'redux-form';
import { FormattedMessage } from 'react-intl';
import { ReduxFormSelectWithValueSelector } from 'react/generic/form/select/Select';

import { isInitiativeType } from '../propTypes';

import messages from './hostingType.messages';

const enhancer = compose(
  memo,
);

const TooltipBody = () => (
  <>
    <FormattedMessage {...messages.HOSTING_TYPE_HINT} />
    <a href={hostingTypesInfoLink} target="_blank" rel="noopener noreferrer"> <FormattedMessage {...messages.HOSTING_TYPE_LINK} /> </a>
  </>
);

const HostingTypeField = ({ isInitiative }) => (
  <Field
    type="text"
    name="hostingType"
    title={<FormattedMessage {...messages.HOSTING_TYPE_TITLE} />}
    tooltip={<TooltipBody />}
    component={ReduxFormSelectWithValueSelector}
    options={hostingTypesOptions}
    isDisabled={isInitiative}
    isSearchable={false}
    menuPlacement="top"
    specific
  />
);

HostingTypeField.displayName = 'HostingTypeField';

HostingTypeField.propTypes = {
  isInitiative: isInitiativeType,
};

HostingTypeField.defaultProps = {
  isInitiative: false,
};

export default enhancer(HostingTypeField);
