import {
  compose,
  withProps,
  withHandlers,
  withPropsOnChange,
} from 'recompose';
import { createForm } from 'rc-form';

import connect from 'react/hoc/connectProxy';
import { selectBusinessUnitById } from 'redux/businessUnits/selectors';
import { selectProductById } from 'redux/products/selectors';

const filterModules = (product, deployment) => (product?.modules || [])
  .filter(m => (deployment?.modules || []).includes(m.id))
  .map(m => ({
    value: m.id,
    label: m.title,
  }));

export default compose(
  createForm(),

  withHandlers({
    onSubmit: ({ form, onSubmit, productId }) => (event) => {
      event.preventDefault();
      event.stopPropagation();
      form.validateFields((err, values) => {
        if (!err) {
          onSubmit({
            ...values,
            product: productId,
            modules: (values.modules || []).map(m => m.value),
            buReference: values.buReference.value,
            version: values.version.value,
            cid: {
              c: values.cidc,
              d: values.cidd,
              i: values.cidi,
            },
          });
        }
      });
    },
  }),

  connect(
    (state, props) => ({
      businessUnit: selectBusinessUnitById(state, props.deployment?.buReference),
      product: selectProductById(state, props.productId),
    }),
  ),

  withPropsOnChange(
    ['product', 'deployment'],
    props => ({
      selectedBusinessUnit: props.businessUnit ? {
        value: props.businessUnit?.id,
        label: props.businessUnit?.name,
      } : null,
      selectedModules: filterModules(props.product, props.deployment),
    }),
  ),

  withProps(({
    form,
    isSubmitting,
    deployment,
    version,
    selectedBusinessUnit,
    selectedModules,
  }) => ({
    canSubmit: !isSubmitting
    && !!form.getFieldValue('buReference')?.value
    && !!form.getFieldValue('version'),

    nicknameDecorator: form.getFieldDecorator(
      'nickname',
      {
        initialValue: deployment?.nickname,
      },
    ),
    buReferenceDecorator: form.getFieldDecorator(
      'buReference',
      {
        initialValue: selectedBusinessUnit,
      },
    ),
    versionDecorator: form.getFieldDecorator(
      'version',
      {
        initialValue: version,
      },
    ),
    cidcDecorator: form.getFieldDecorator(
      'cidc',
      {
        initialValue: deployment?.cid?.c,
      },
    ),
    ciddDecorator: form.getFieldDecorator(
      'cidd',
      {
        initialValue: deployment?.cid?.d,
      },
    ),
    cidiDecorator: form.getFieldDecorator(
      'cidi',
      {
        initialValue: deployment?.cid?.i,
      },
    ),
    modulesDecorator: form.getFieldDecorator(
      'modules',
      {
        initialValue: selectedModules,
      },
    ),
  })),
);
