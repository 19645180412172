import React, { memo } from 'react';
import { compose, withProps } from 'recompose';

import { FormattedMessage } from 'react-intl';

import DeploymentDialogStepForm from 'react/business/deployments/form/dialog/base/steps/Form';

import globalMessages from 'config/global.messages';
import messages from '../create-deployment-form.messages';

export default compose(
  memo,

  withProps(() => ({
    title: <FormattedMessage {...messages.DEPLOYMENT_FORM_DIALOG_TITLE} />,
    submitMessage: <FormattedMessage {...globalMessages.SUBMIT} />,
  })),
)(DeploymentDialogStepForm);
