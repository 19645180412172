import { defineMessages } from 'react-intl';

export default defineMessages({
  BUSINESS_ACTIVITIES_HINT: {
    id: 'pages.product.form.step.6.businessActivities',
    defaultMessage: 'Which business activities are related to your product?',
  },
  HEADER_TITLE: {
    id: 'pages.product.form.step.6.header.title',
    defaultMessage: 'Business Activities related to the product',
  },
});
