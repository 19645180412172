import React, { memo } from 'react';
import { compose } from 'recompose';

import DeploymentFormFailureStep from 'react/business/versions/form/base/steps/failure/Failure';
import DeploymentDialogResultStep from 'react/business/deployments/form/dialog/base/ResultStep';

const enhancer = compose(
  memo,
);

const DeploymentDialogStepFailure = props => (
  <DeploymentDialogResultStep>
    <DeploymentFormFailureStep {...props} />
  </DeploymentDialogResultStep>
);

DeploymentDialogStepFailure.displayName = 'DeploymentDialogStepFailure';

export default enhancer(DeploymentDialogStepFailure);
