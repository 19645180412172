import React, { memo } from 'react';
import { compose, withHandlers, withProps } from 'recompose';
import PropTypes from 'prop-types';
import { isEmpty, noop } from 'lodash';
import { FormattedMessage } from 'react-intl';
import { withRouter } from 'react-router-dom';
import { formatRoute } from 'react-router-named-routes';

import ROUTES from 'react/routes';
import { PRODUCT_FORM_STEPS } from 'react/business/products/form/steps/form-steps.constants';

import { productShape } from 'shapes/product';
import ProductSummary from 'react/business/products/details/summary/ProductSummary';
import ProductDescription from 'react/business/products/details/description/ProductDescription';
import ProductTeam from 'react/business/products/details/team/ProductTeam';
import ProductFeatures from 'react/business/products/details/features/ProductFeatures';
import ProductBusinessUnits from 'react/business/products/details/businessUnits/ProductBusinessUnits';
import ProductInnersource from 'react/business/products/details/innersource/ProductInnersource';
import ProductSubHeader from 'react/business/products/details/subHeader/ProductSubHeader';
import ProductBusinessActivities from 'react/business/products/details/businessActivities/ProductBusinessActivities';
import ProductArchitectureBuildingBlocks from 'react/business/products/details/architectureBuildingBlocks/ProductArchitectureBuildingBlocks';
import EditProductButton from 'react/business/products/editButton/editProductButton/EditProductButton';
import messages from 'react/pages/product/productPage/productpage.messages';
import { getCompletionRateColor } from 'utils';

import { Tooltip } from 'antd';
import Asset, { ICON_MAP } from 'react/generic/asset/Asset';
import { withProductPageContext } from '../../ProductPage.context';
import classNames from './overview-page.module.scss';
import ProductSubsets from '../../../../../business/products/details/subsets/ProductSubsets';
import { COMPLETION_RATE_LINK } from './overview-page.constants';

const enhancer = compose(
  withRouter,

  withProductPageContext(),

  withHandlers({
    onClickEdit: ({ product, history }) => () => {
      history.push(formatRoute(
        ROUTES.PRODUCT.EDIT_FORM,
        {
          productId: product.id,
          step: PRODUCT_FORM_STEPS.PRESENTATION,
        },
      ));
    },
  }),

  withProps({
    shouldDisplayTeamPart: ({ product }) => !isEmpty(product.teamMembers) || product.author,
  }),

  memo,
);

const OverviewPage = ({
  product,
  isInitiative,
  isPreview,
  canEditProduct,
  onClickEdit,
  shouldDisplayTeamPart,
}) => (
  <>
    {
      (canEditProduct) && (!isPreview) && (
        <ProductSubHeader>
          <EditProductButton
            productId={product.id}
            onClickEdit={onClickEdit}
            labelButton={<FormattedMessage {...messages.EDIT_PRODUCT_BUTTON} />}
          />
          <div className={classNames.subheaderRight}>
            <div className={`${classNames.completionRate} ${classNames[getCompletionRateColor(product.completionRate)]}`}>
              <FormattedMessage
                {...messages.COMPLETION_RATE}
                values={{ completionRate: product.completionRate }}
              />
              <Tooltip
                placement="bottomRight"
                title={(
                  <FormattedMessage
                    {...messages.COMPLETION_RATE_TOOLTIP}
                    values={{
                      link: (
                        <a href={COMPLETION_RATE_LINK} target="_blank" rel="noopener noreferrer" key="id_all">
                          <FormattedMessage {...messages.COMPLETION_RATE_LINK} />
                        </a>
                      ),
                    }}
                  />
                )}
              >
                <div className={classNames.tooltipCompletionRate}>
                  <Asset name={ICON_MAP.info} />
                </div>
              </Tooltip>
            </div>
          </div>

        </ProductSubHeader>
      )
    }
    <div className={classNames.container}>
      <ProductSummary product={product} />

      <ProductDescription description={product.presentation} />
      {shouldDisplayTeamPart({ product }) && (
        <ProductTeam product={product} />
      )}

      <ProductFeatures
        product={product}
        isPreview={isPreview}
      />

      {!isInitiative && <ProductBusinessUnits product={product} />}

      <ProductInnersource product={product} />

      <ProductBusinessActivities product={product} />

      <ProductArchitectureBuildingBlocks product={product} />

      <ProductSubsets product={product} />
    </div>
  </>
);

OverviewPage.displayName = 'OverviewPage';

OverviewPage.propTypes = {
  product: productShape.isRequired,
  isInitiative: PropTypes.bool,
  isPreview: PropTypes.bool,
  canEditProduct: PropTypes.bool,
  onClickEdit: PropTypes.func,
  shouldDisplayTeamPart: PropTypes.func,
};

OverviewPage.defaultProps = {
  isInitiative: false,
  isPreview: false,
  canEditProduct: false,
  onClickEdit: noop,
  shouldDisplayTeamPart: noop,
};

export default enhancer(OverviewPage);
