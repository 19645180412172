import React, { memo } from 'react';
import { compose, withHandlers } from 'recompose';
import { noop } from 'lodash';
import { formatRoute } from 'react-router-named-routes';
import queryString from 'query-string';
import PropTypes from 'prop-types';
import { Tooltip } from 'antd';

import ROUTES from 'react/routes';

import { productShape } from 'shapes/product';
import { domainShape, communityShape } from 'shapes/community';
import withMediumBreakpoint from 'react/hoc/withMediumBreakpoint';

import SafeHtml from 'react/generic/html/SafeHtml';
import Link from 'react/generic/link/Link';
import ProductRibbonSwitch from 'react/business/products/ribbons/ProductRibbonSwitch';
import ProductLogo from 'react/business/products/logo/ProductLogo';
import FollowProductButton from 'react/business/products/followButton/FollowProductButton';
import TranslateButton from 'react/generic/translation/TranslateButton';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { withToastManager } from 'react-toast-notifications';
import Asset, { ICON_MAP } from 'react/generic/asset/Asset';
import IconButton from 'react/generic/button/IconButton';
import { PRODUCT_VIEW } from 'config/constants';
import DraftFlag from '../draftFlag/DraftFlag';
import classNames from './product-header.module.scss';

const commonToastOptions = {
  autoDismiss: true,
  pauseOnHover: true,
  appearance: 'success',
};

const enhancer = compose(
  memo,
  withMediumBreakpoint,
  withToastManager,
  withHandlers({
    addSuccessToast: ({ toastManager, product }) => () => {
      toastManager.add(
        <p>The ID of product {product.name} has been copied to clipboard</p>,
        {
          ...commonToastOptions,
        },
      );
    },
  }),
);

const ProductHeader = ({
  product,
  domain,
  community,
  isTranslatedContentDisplayed,
  onRequestTranslate,
  addSuccessToast,
}) => (
  <div className={classNames.container}>
    <div className={classNames.main}>
      <div className={classNames.imgContainer}>
        <ProductRibbonSwitch product={product} />

        <ProductLogo
          product={product}
          className={classNames.img}
        />
      </div>

      <div className={classNames.side}>
        <div className={classNames.infoContainer}>
          <div className={classNames.communityDomainContainer}>
            { community
              && (
                <span className={classNames.community}>
                  <Tooltip
                    title={
                      community?.description ?
                        <SafeHtml unsafeHtml={community.description} />
                        : ''
                    }
                  >
                    <Link to={`${formatRoute(ROUTES.PRODUCTS)}?${queryString.stringify({ communityIds: community.id })}`}>
                      {community.name}
                    </Link>
                  </Tooltip>
                </span>
              ) }

            { domain
              && (
                <span className={classNames.domain}>
                  <Tooltip
                    title={
                      domain?.description ?
                        <SafeHtml unsafeHtml={domain.description} />
                        : ''
                    }
                  >
                    <Link to={`${formatRoute(ROUTES.PRODUCTS)}?${queryString.stringify({ domainIds: domain.id })}`}>
                      {domain.name}
                    </Link>
                  </Tooltip>
                </span>
              ) }

            { product.parentDigitalProduct
              && (
                <span className={classNames.parent}>
                  <Link to={`${formatRoute(ROUTES.PRODUCT.PRESENTATION,
                    {
                      productId: product.parentDigitalProduct.id,
                      view: PRODUCT_VIEW.OVERVIEW,
                    })
                  }`}
                  >
                    {product.parentDigitalProduct.name}
                  </Link>
                </span>
              ) }
          </div>
          <div className={classNames.titleContainer}>
            <h1 className={classNames.title}>{product.name}</h1>
            { product.version
              && <div className={classNames.version}>{product.version}</div> }
          </div>
        </div>
      </div>

      <div className={classNames.draftFlag}>
        { product.isDraft
          && (
            <DraftFlag isDraft={product.isDraft} />
          )
        }
      </div>

      <div className={classNames.buttonsContainer}>
        <FollowProductButton
          productId={product.id}
          productName={product.name}
        />
        <TranslateButton
          className={classNames.translateButton}
          isTranslatedContentDisplayed={isTranslatedContentDisplayed}
          onRequestTranslate={onRequestTranslate}
        />

        <Tooltip title="Copy ID">
          <CopyToClipboard text={product.id} onCopy={addSuccessToast}>
            <IconButton
              outline
              overrideClassName={classNames.copyButton}
              overrideClassNames={classNames}
            >

              <Asset name={ICON_MAP.draft} />
            </IconButton>
          </CopyToClipboard>
        </Tooltip>
      </div>
    </div>
  </div>
);

ProductHeader.displayName = 'ProductHeader';

ProductHeader.propTypes = {
  product: productShape.isRequired,
  domain: domainShape,
  community: communityShape,
  isTranslatedContentDisplayed: PropTypes.bool,
  onRequestTranslate: PropTypes.func,
  addSuccessToast: PropTypes.func,
};

ProductHeader.defaultProps = {
  domain: null,
  community: null,
  isTranslatedContentDisplayed: false,
  onRequestTranslate: noop,
  addSuccessToast: noop,
};

export default enhancer(ProductHeader);
