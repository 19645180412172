import { noop } from 'lodash';
import React, { memo } from 'react';
import { compose } from 'recompose';
import PropTypes from 'prop-types';
import cn from 'classnames';

import { FormattedMessage } from 'react-intl';
import Button from 'react/generic/button/Button';
import Asset, { ICON_MAP } from 'react/generic/asset/Asset';

import { FEEDBACK_MESSAGES } from 'config/constants.messages';
import classNames from './failure.module.scss';

const enhancer = compose(
  memo,
);

const VersionFormFailureStep = ({
  className,
  onClickTryAgain,
}) => (
  <div className={cn(className, classNames.container, classNames.uhoh)}>
    <Asset
      name={ICON_MAP.thumb_down}
      className={classNames.icon}
      aria-hidden
    />

    <h1 className={classNames.title}>
      <FormattedMessage {...FEEDBACK_MESSAGES.UH_OH} />
    </h1>

    <p className={classNames.body}>
      <FormattedMessage {...FEEDBACK_MESSAGES.SOMETHING_WENT_WRONG} />
    </p>

    <p className={classNames.buttonContainer}>
      <Button
        onClick={onClickTryAgain}
        overrideClassName={classNames.button}
        overrideClassNames={classNames}
      >
        <FormattedMessage {...FEEDBACK_MESSAGES.TRY_AGAIN} />
      </Button>
    </p>
  </div>
);

VersionFormFailureStep.displayName = 'VersionFormFailureStep';

VersionFormFailureStep.propTypes = {
  className: PropTypes.string,
  onClickTryAgain: PropTypes.func,
};

VersionFormFailureStep.defaultProps = {
  className: null,
  onClickTryAgain: noop,
};

export default enhancer(VersionFormFailureStep);
