import { FormattedMessage } from 'react-intl';
import React, { memo } from 'react';
import { compose } from 'recompose';
import SectionTitle from '../layout/sectionTitle/SectionTitle';
import messages from '../../../../../config/business.messages';
import Section from '../layout/section/Section';
import ProductSubsetsList from '../../../subsets/list/ProductSubsetsList';
import { productShape } from '../../../../../shapes/product';

const enhancer = compose(
  memo,
);

const ProductSubsets = ({ product }) => (
  <Section>
    <SectionTitle centered>
      <FormattedMessage {...messages.SUBSETS} />
    </SectionTitle>

    <div>
      <ProductSubsetsList
        subsetIds={product.subsets}
        communityId={product.communityId}
      />
    </div>
  </Section>
);

ProductSubsets.displayName = 'ProductSubsets';

ProductSubsets.propTypes = {
  product: productShape,
};

ProductSubsets.defaultProps = {
  product: {},
};

export default enhancer(ProductSubsets);
