import React, {
  memo, useEffect, useState,
} from 'react';
import PropTypes from 'prop-types';
import { noop, without } from 'lodash';
import { FormattedMessage } from 'react-intl';
import { getFormValues } from 'redux-form';
import {
  compose,
  withPropsOnChange,
  withHandlers,
  branch,
  renderComponent, withState,
} from 'recompose';
import cn from 'classnames';

import { architectureBuildingBlockShape } from 'shapes/architectureBuildingBlocks';

import connect from 'react/hoc/connectProxy';
import omitProps from 'react/hoc/omitProps';
import { withProductFormName } from 'react/business/products/form/Context';

import withCommunityArchitectureBuildingBlocks from './connect/withCommunityArchitectureBuildingBlocks.connect';
import ArchitectureBuildingBlocksList from './list/ArchitectureBuildingBlocksList';

import messages from './abb-selector.messages';
import classNames from './abb-selector.module.scss';

const NoCommunityComponent = () => (
  <div className={classNames.noContentContainer}>
    <FormattedMessage {...messages.NO_COMMUNITY} />
  </div>
);

const enhancer = compose(
  withProductFormName(),

  connect(
    (state, props) => ({
      community: getFormValues(props.formName)(state).community,

    }),
  ),

  withPropsOnChange(
    ['community'],
    ({ community }) => ({
      communityId: community?.value,
      communityName: community?.label,
    }),
  ),

  branch(
    ({ communityId }) => !communityId,
    renderComponent(NoCommunityComponent),
  ),

  withCommunityArchitectureBuildingBlocks,
  withState('abbCounter', 'setAbbCounter', {}),
  withState('abbsTemp', 'setAbbsTemp', []),

  withHandlers(
    ({

      onToggleElement: ({
        value: currentValue, onChange, abbCounter, setAbbCounter, abbsTemp,
      }) => (id, value) => {
        if (value === true) {
          onChange((currentValue || []).concat(id));

          const tempAbbCounter = { ...abbCounter };

          abbsTemp.forEach((abb) => {
            if (abb.id === id) {
              if (!tempAbbCounter[abb.domain]) {
                tempAbbCounter[abb.domain] = 0;
              }
              ++tempAbbCounter[abb.domain];
            }
          });

          setAbbCounter(tempAbbCounter);
        } else {
          onChange(without(currentValue, id));
          const tempAbbCounter = { ...abbCounter };
          abbsTemp.forEach((abb) => {
            if (abb.id === id) {
              --tempAbbCounter[abb.domain];
            }
          });
          setAbbCounter(tempAbbCounter);
        }
      },
    }),
  ),

  omitProps(['community', 'communityId', 'formName']),

  memo,

);

const ArchitectureBuildingBlocksSelector = ({
  abbs,
  value,
  onToggleElement,
  abbCounter, setAbbCounter, setAbbsTemp,
}) => {
  const [selectedDomain, setSelectedDomain] = useState();
  const [initialAbbCounter, setInitialAbbCounter] = useState(false);

  /* eslint-disable-next-line no-param-reassign */
  if (!value) value = [];

  useEffect(() => { // ON MOUNT
    if (!abbs.length || initialAbbCounter) return;
    setInitialAbbCounter(true);
    setAbbsTemp(abbs);

    const tempAbbCounter = {};

    value.forEach((data) => {
      abbs.forEach((abb) => {
        if (abb.id === data) {
          if (!tempAbbCounter[abb.domain]) {
            tempAbbCounter[abb.domain] = 0;
          }
          ++tempAbbCounter[abb.domain];
        }
      });
    });
    setAbbCounter(tempAbbCounter);
  }, [value, abbCounter, abbs, initialAbbCounter, setAbbsTemp, setAbbCounter]);
  const onSelectDomain = (elt) => {
    setSelectedDomain(elt.id);
  };

  const convertDomains = (abbsMap) => {
    const domains = (abbsMap).map(abb => ({ id: abb.domain, name: abb.domain }));
    const resArr = [];
    domains.filter((item) => {
      // If abb has no domain, skip.
      if (!item?.id) return null;

      const i = resArr.findIndex(x => x.name === item.name);
      if (i <= -1) {
        resArr.push({ id: item.id, name: item.name });
      }
      return null;
    });

    return resArr.sort((a, b) => a.id.localeCompare(b.id));
  };

  const filterAbbs = (abbsMap, domain) => Object.values(abbsMap)
    .filter(a => a.domain === domain)
    .sort((a, b) => a.id.localeCompare(b.id));

  const headerDomain = <FormattedMessage {...messages.DOMAINS} />;

  const headerABB = <FormattedMessage {...messages.ABBS} />;

  return (
    <div className={cn(classNames.container)}>
      <ArchitectureBuildingBlocksList
        items={convertDomains(abbs)}
        onClickElement={onSelectDomain}
        header={headerDomain}
        selectedLineId={selectedDomain}
        counters={abbCounter}
      />
      <ArchitectureBuildingBlocksList
        items={filterAbbs(abbs, selectedDomain)}
        onToggleElement={onToggleElement}
        value={value}
        header={headerABB}
      />
    </div>
  );
};

ArchitectureBuildingBlocksSelector.displayName = 'ArchitectureBuildingBlocksSelector';

ArchitectureBuildingBlocksSelector.propTypes = {
  abbs: PropTypes.arrayOf(architectureBuildingBlockShape),
  value: PropTypes.arrayOf(PropTypes.string),
  onToggleElement: PropTypes.func,
  abbCounter: PropTypes.objectOf(PropTypes.number),
  setAbbCounter: PropTypes.func,
  setAbbsTemp: PropTypes.func,

};

ArchitectureBuildingBlocksSelector.defaultProps = {
  abbs: [],
  value: [],
  onToggleElement: noop,
  abbCounter: {},
  setAbbCounter: noop,
  setAbbsTemp: noop,
};

export default enhancer(ArchitectureBuildingBlocksSelector);
