import React, { memo } from 'react';
import { compose, withProps } from 'recompose';
import { FormattedMessage } from 'react-intl';

import DeploymentDialogStepSuccess from 'react/business/deployments/form/dialog/base/steps/Success';

import messages from '../edit-deployment-form.messages';

export default compose(
  memo,

  withProps(() => ({
    successMessage: (
      <FormattedMessage {...messages.DEPLOYMENT_EDIT_SUCCESS} />
    ),
  })),
)(DeploymentDialogStepSuccess);
